import React from 'react';
import { CellRenderType } from 'enums';
import { Conference } from 'types/conferences';

export const initialColumns: Conference = {
    id: '',
    name: '',
    creationDate: new Date(),
    isCurrent: false,
};

export const cellRenderMapper: Record<keyof Conference, CellRenderType> = {
    id: CellRenderType.TEXT,
    name: CellRenderType.TEXT,
    creationDate: CellRenderType.DATETIME,
    isCurrent: CellRenderType.BOOLEAN,
};
