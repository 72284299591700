import { CellRenderType } from 'enums';
import { ProgramModerator } from 'types';

export const initialColumns: ProgramModerator = {
    id: '',
    creationDate: new Date(),
    firstName: '',
    lastName: '',
};

export const cellRenderMapper: Record<keyof ProgramModerator, CellRenderType> = {
    id: CellRenderType.TEXT,
    creationDate: CellRenderType.DATETIME,
    firstName: CellRenderType.TEXT,
    lastName: CellRenderType.TEXT,
    position: CellRenderType.TEXT,
};
