import React, { PropsWithChildren, Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import {
    Participants,
    NotAuthorized,
    SignIn,
    SignOut,
    Agreements,
    Agreement,
    Speakers,
    Config,
    MarketingRequests,
    AccessDenied,
    Sponsors,
    Conferences,
    Conference,
    Speaker,
    Sponsor,
    Participant,
    ContentBase,
    Committes,
    Committee,
    SpeakersTags,
    SpeakersTag,
    SpeakersContents,
    SpeakersContent,
    SponsorsContents,
    SponsorsContent,
    ProgramBase,
    ProgramTags,
    ProgramTag,
    ProgramModerators,
    ProgramModerator,
    ProgramEvents,
    ProgramEvent,
    ProgramSessions,
    ProgramSession,
} from 'containers';
import {
    ParticipantsProvider,
    AgreementsProvider,
    SpeakersProvider,
    ConfigProvider,
    useAuth,
    MarketingRequestsProvider,
    SponsorsProvider,
    ConferenceProvider,
    CommitteesProvider,
    SpeakersTagsProvider,
    SpeakersContentProvider,
    SponsorsContentProvider,
    ProgramTagsProvider,
    ProgramModeratorsProvider,
    ProgramEventsProvider,
    ProgramSessionsProvider,
} from 'contexts';
import { Loader } from '@kl/components-v6';
import { useTranslation } from 'react-i18next';

const Router: React.FC<PropsWithChildren> = (): JSX.Element => {
    const { isAuth } = useAuth();
    const { t } = useTranslation('common/shared');

    return (
        <Suspense fallback={<Loader centered size={'large'} tip={t('loading')} />}>
            <Routes>
                {isAuth ? (
                    <>
                        <Route path="/" element={<Navigate to="/participants" />} />
                        <Route
                            path="/participants"
                            element={
                                <ConferenceProvider>
                                    <ParticipantsProvider>
                                        <Participants />
                                    </ParticipantsProvider>
                                </ConferenceProvider>
                            }
                        />
                        <Route
                            path="/participant/:id"
                            element={
                                <ParticipantsProvider>
                                    <Participant />
                                </ParticipantsProvider>
                            }
                        />
                        <Route
                            path="/agreements/:id?"
                            element={
                                <AgreementsProvider>
                                    <Agreements />
                                </AgreementsProvider>
                            }
                        />
                        <Route
                            path="/agreement/:id"
                            element={
                                <AgreementsProvider>
                                    <Agreement />
                                </AgreementsProvider>
                            }
                        />
                        <Route
                            path="/conferences"
                            element={
                                <ConferenceProvider>
                                    <Conferences />
                                </ConferenceProvider>
                            }
                        />
                        <Route
                            path="/conference/:id?"
                            element={
                                <ConferenceProvider>
                                    <Conference />
                                </ConferenceProvider>
                            }
                        />
                        <Route
                            path="/speakers"
                            element={
                                <ConferenceProvider>
                                    <SpeakersProvider>
                                        <Speakers />
                                    </SpeakersProvider>
                                </ConferenceProvider>
                            }
                        />
                        <Route
                            path="/speaker/:id"
                            element={
                                <SpeakersProvider>
                                    <Speaker />
                                </SpeakersProvider>
                            }
                        />
                        <Route
                            path="/sponsors"
                            element={
                                <ConferenceProvider>
                                    <SponsorsProvider>
                                        <Sponsors />
                                    </SponsorsProvider>
                                </ConferenceProvider>
                            }
                        />
                        <Route
                            path="/sponsor/:id"
                            element={
                                <SponsorsProvider>
                                    <Sponsor />
                                </SponsorsProvider>
                            }
                        />
                        <Route
                            path="/marketing-requests"
                            element={
                                <MarketingRequestsProvider>
                                    <MarketingRequests />
                                </MarketingRequestsProvider>
                            }
                        />
                        <Route
                            path="/config"
                            element={
                                <ConfigProvider>
                                    <Config />
                                </ConfigProvider>
                            }
                        />
                        <Route path="/content" element={<ContentBase />}>
                            <Route
                                path="general"
                                element={
                                    <ConfigProvider>
                                        <Config />
                                    </ConfigProvider>
                                }
                            />
                            <Route
                                path="committees"
                                element={
                                    <CommitteesProvider>
                                        <Committes />
                                    </CommitteesProvider>
                                }
                            />
                            <Route
                                path="speakers-tags"
                                element={
                                    <SpeakersTagsProvider>
                                        <SpeakersTags />
                                    </SpeakersTagsProvider>
                                }
                            />
                            <Route
                                path="speakers"
                                element={
                                    <SpeakersContentProvider>
                                        <ConferenceProvider>
                                            <SpeakersContents />
                                        </ConferenceProvider>
                                    </SpeakersContentProvider>
                                }
                            />
                            <Route
                                path="sponsors"
                                element={
                                    <SponsorsContentProvider>
                                        <SponsorsContents />
                                    </SponsorsContentProvider>
                                }
                            />
                        </Route>
                        <Route
                            path="/committee/:id?"
                            element={
                                <CommitteesProvider>
                                    <Committee />
                                </CommitteesProvider>
                            }
                        />
                        <Route
                            path="/speakers-tag/:id?"
                            element={
                                <SpeakersTagsProvider>
                                    <SpeakersTag />
                                </SpeakersTagsProvider>
                            }
                        />
                        <Route
                            path="/speakers-content/:id?"
                            element={
                                <SpeakersTagsProvider>
                                    <SpeakersContentProvider>
                                        <ConferenceProvider>
                                            <SpeakersContent />
                                        </ConferenceProvider>
                                    </SpeakersContentProvider>
                                </SpeakersTagsProvider>
                            }
                        />
                        <Route
                            path="/sponsors-content/:id?"
                            element={
                                <SponsorsContentProvider>
                                    <SponsorsContent />
                                </SponsorsContentProvider>
                            }
                        />
                        <Route path="/program" element={<ProgramBase />}>
                            <Route
                                path="tags"
                                element={
                                    <ProgramTagsProvider>
                                        <ProgramTags />
                                    </ProgramTagsProvider>
                                }
                            />
                            <Route
                                path="tag/:id?"
                                element={
                                    <ProgramTagsProvider>
                                        <ProgramTag />
                                    </ProgramTagsProvider>
                                }
                            />
                            <Route
                                path="moderators"
                                element={
                                    <ProgramModeratorsProvider>
                                        <ProgramModerators />
                                    </ProgramModeratorsProvider>
                                }
                            />
                            <Route
                                path="moderator/:id?"
                                element={
                                    <ProgramModeratorsProvider>
                                        <ProgramModerator />
                                    </ProgramModeratorsProvider>
                                }
                            />
                            <Route
                                path="events"
                                element={
                                    <ConferenceProvider>
                                        <ProgramEventsProvider>
                                            <ProgramEvents />
                                        </ProgramEventsProvider>
                                    </ConferenceProvider>
                                }
                            />
                            <Route
                                path="event/:id?"
                                element={
                                    <SpeakersContentProvider>
                                        <ConferenceProvider>
                                            <ProgramTagsProvider>
                                                <ProgramEventsProvider>
                                                    <ProgramEvent />
                                                </ProgramEventsProvider>
                                            </ProgramTagsProvider>
                                        </ConferenceProvider>
                                    </SpeakersContentProvider>
                                }
                            />
                            <Route
                                path="sessions"
                                element={
                                    <ConferenceProvider>
                                        <ProgramSessionsProvider>
                                            <ProgramSessions />
                                        </ProgramSessionsProvider>
                                    </ConferenceProvider>
                                }
                            />
                            <Route
                                path="session/:id?"
                                element={
                                    <ConferenceProvider>
                                        <ProgramModeratorsProvider>
                                            <ProgramEventsProvider>
                                                <ProgramSessionsProvider>
                                                    <ProgramSession />
                                                </ProgramSessionsProvider>
                                            </ProgramEventsProvider>
                                        </ProgramModeratorsProvider>
                                    </ConferenceProvider>
                                }
                            />
                        </Route>
                    </>
                ) : (
                    <>
                        <Route path="/access-denied" element={<AccessDenied />} />
                        <Route path="*" element={<NotAuthorized />} />
                    </>
                )}
                <Route path="/login" element={<SignIn />} />
                <Route path="/logout" element={<SignOut />} />
            </Routes>
        </Suspense>
    );
};

export default Router;
