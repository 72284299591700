import React, { createContext, FC, Key, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { getApiService, getSpeakersApiService, getStorageService } from 'services';
import { GetSpeakers, HttpResponse, Speaker } from 'types';
import { PaginationOutput, downloadFileFromBase64 } from 'kl-b2c-ui-kit';

interface SpeakersContext {
    speakers: PaginationOutput<Speaker>;
    getSpeakers: (params: GetSpeakers) => Promise<void>;
    getSpeaker: (id: string) => Promise<Speaker>;
    getExcel: (params: Omit<GetSpeakers, 'page' | 'size'>) => Promise<void>;
    deleteSpeakers: (ids: Key[]) => Promise<HttpResponse<string>>;
}

const SpeakersContext = createContext<SpeakersContext>({} as SpeakersContext);

const SpeakersProvider: FC<PropsWithChildren> = ({ children }) => {
    const storageService = getStorageService();
    const [speakers, setSpeakers] = useState<PaginationOutput<Speaker>>({
        count: 0,
        items: null,
    });
    const apiService = getApiService(storageService);
    const speakersApiService = getSpeakersApiService(apiService);

    const getSpeakers = async (params: GetSpeakers) => {
        const speakers = await speakersApiService.getItems(params);
        setSpeakers(speakers.data);
    };

    const getSpeaker = async (id: string) => {
        const speaker = await speakersApiService.getItem(id);
        return speaker.data;
    };

    const deleteSpeakers = async (ids: Key[]) => {
        return await speakersApiService.deleteMany(ids);
    };

    const getExcel = async (params: Omit<GetSpeakers, 'page' | 'size'>) => {
        const base64 = await speakersApiService.getExcel(params);
        downloadFileFromBase64(base64.data, 'xlsx', 'speakers');
    };

    const memoValue = useMemo(
        () => ({
            getSpeakers,
            speakers,
            getExcel,
            deleteSpeakers,
            getSpeaker,
        }),
        [getSpeakers, speakers]
    );

    return <SpeakersContext.Provider value={memoValue}>{children}</SpeakersContext.Provider>;
};

export const useSpeakers = () => useContext(SpeakersContext);

export default SpeakersProvider;
