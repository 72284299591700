import React, { FC, useEffect } from 'react';
import { useConferences, useSpeakers } from 'contexts';
import { cellRenderMapper, initialColumns } from './mappers';
import { Speaker, GetSpeakers } from 'types/speakers';
import { renderCell, getTableColumns } from 'utils';
import { PageBuilder } from 'containers';
import { FilterType, PageBuilderAdditionalFilters, PageBuilderKey } from 'enums';
import { useTranslation } from 'react-i18next';
import { Conference } from 'types';
import { useNavigate } from 'react-router-dom';

const Speakers: FC = () => {
    const { speakers, getSpeakers, getExcel, deleteSpeakers } = useSpeakers();
    const { i18n } = useTranslation('common/shared');
    const navigate = useNavigate();

    const { getConferences, conferences, getCurrentConference, currentConference } = useConferences();

    const mapConferences = (conferences: Conference[]) =>
        conferences.map((item) => ({
            value: item.id,
            label: item.name,
        }));

    useEffect(() => {
        getConferences({ page: 0, size: 300 });
    }, []);

    return (
        <PageBuilder<Speaker>
            pageKey={PageBuilderKey.Speakers}
            data={speakers}
            getItems={(params) => getSpeakers(params as GetSpeakers)}
            getExcel={(params) => getExcel(params as Omit<GetSpeakers, 'page' | 'size'>)}
            deleteItems={deleteSpeakers}
            showItem={(speaker: Speaker) => navigate(`/speaker/${speaker.id}`)}
            additionalFilters={[
                { type: PageBuilderAdditionalFilters.DebounceTextBox, key: FilterType.Email },
                {
                    type: PageBuilderAdditionalFilters.DropdownWithSearch,
                    key: FilterType.Conference,
                    items: mapConferences(conferences.items ?? []),
                    withEmpty: true,
                    defaultValueFetchAsync: () => getCurrentConference().then((d) => d?.id),
                    defaultValue: currentConference?.id,
                },
            ]}
            columns={getTableColumns<Omit<Speaker, 'id'>>(
                initialColumns,
                cellRenderMapper,
                renderCell,
                i18n.getResourceBundle(i18n.language, 'common/shared')
            )}
        />
    );
};

export default Speakers;
