import { ProgramModerator } from 'types';
import { ControlRendererType } from 'enums';
import { ControlRendererRepresentation } from 'types/form-builder/control-renderer-config';

type EditType = Omit<ProgramModerator, 'id' | 'creationDate'>;

export const programModeratorRendererTypes = (
    t: (key: string, options?: Record<string, number | string>) => string
): Record<keyof EditType, ControlRendererRepresentation> => ({
    firstName: {
        type: ControlRendererType.TextBox,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 255,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 255,
                }),
            },
        },
    },
    position: {
        type: ControlRendererType.TextBox,
        rules: {
            maxLength: {
                value: 255,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 255,
                }),
            },
        },
    },
    lastName: {
        type: ControlRendererType.TextBox,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 255,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 255,
                }),
            },
        },
    },
});
