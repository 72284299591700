import { ApiService, TokenApi, BaseApi, ConfigApi, ConferenceApi, AgreementApi } from './api';
import {
    IStorageService,
    ITokenApi,
    IApiService,
    ISettingsService,
    IBaseApi,
    IConfigApi,
    IConferenceApi,
    IAgreementApi,
} from 'interfaces';
import { SettingsService, StorageService } from 'services';
import {
    GetSpeakers,
    Speaker,
    Participant,
    GetParticipants,
    GetMarketingRequests,
    MarketingRequest,
    GetSponsors,
    Sponsor,
    GetCommittees,
    Committee,
    SpeakersTag,
    GetSpeakersContents,
    SpeakersContent,
    SponsorsContent,
    GetSponsorsContents,
    GetProgramTags,
    ProgramTag,
    GetProgramModerators,
    ProgramModerator,
    GetProgramEvents,
    ProgramEvent,
    GetProgramSessions,
    ProgramSession,
} from 'types';
import { ApiBaseKeys } from 'enums';

export const getApiService = (tokenStorageService: IStorageService): IApiService => new ApiService(tokenStorageService);
export const geTokenApiService = (apiService: IApiService): ITokenApi => new TokenApi(apiService);
export const getConfigApiService = (apiService: IApiService): IConfigApi => new ConfigApi(apiService);
export const getParticipantsApiService = (apiService: IApiService): IBaseApi<GetParticipants, Participant> =>
    new BaseApi(apiService, ApiBaseKeys.Participants);
export const getSponsorsApiService = (apiService: IApiService): IBaseApi<GetSponsors, Sponsor> =>
    new BaseApi(apiService, ApiBaseKeys.Sponsors);
export const getConferencesApiService = (apiService: IApiService): IConferenceApi => new ConferenceApi(apiService);
export const getAgreementsApiService = (apiService: IApiService): IAgreementApi => new AgreementApi(apiService);
export const getSpeakersApiService = (apiService: IApiService): IBaseApi<GetSpeakers, Speaker> =>
    new BaseApi(apiService, ApiBaseKeys.Speakers);
export const getMarketingRequestsApiService = (
    apiService: IApiService
): IBaseApi<GetMarketingRequests, MarketingRequest> => new BaseApi(apiService, ApiBaseKeys.MarketingRequests);
export const getStorageService = (): IStorageService => new StorageService();
export const getSettingsService = (): ISettingsService => new SettingsService();
export const getCommitteesApiService = (apiService: IApiService): IBaseApi<GetCommittees, Committee> =>
    new BaseApi(apiService, ApiBaseKeys.Committees);
export const getSpeakersTagsApiService = (apiService: IApiService): IBaseApi<GetSpeakers, SpeakersTag> =>
    new BaseApi(apiService, ApiBaseKeys.SpeakersTags);
export const getSpeakersContentsApiService = (
    apiService: IApiService
): IBaseApi<GetSpeakersContents, SpeakersContent> => new BaseApi(apiService, ApiBaseKeys.SpeakersContents);
export const getSponsorsContentsApiService = (
    apiService: IApiService
): IBaseApi<GetSponsorsContents, SponsorsContent> => new BaseApi(apiService, ApiBaseKeys.SponsorsContents);
export const getProgramTagsApiService = (apiService: IApiService): IBaseApi<GetProgramTags, ProgramTag> =>
    new BaseApi(apiService, ApiBaseKeys.ProgramTags);
export const getProgramModeratorsApiService = (
    apiService: IApiService
): IBaseApi<GetProgramModerators, ProgramModerator> => new BaseApi(apiService, ApiBaseKeys.ProgramModerators);
export const getProgramEventsApiService = (apiService: IApiService): IBaseApi<GetProgramEvents, ProgramEvent> =>
    new BaseApi(apiService, ApiBaseKeys.ProgramEvents);
export const getProgramSessionsApiService = (apiService: IApiService): IBaseApi<GetProgramSessions, ProgramSession> =>
    new BaseApi(apiService, ApiBaseKeys.ProgramSessions);
