import { GetFile, formatBytes } from 'kl-b2c-ui-kit';
import { ACCEPTED_IMAGE_FORMATS, TEN_MB_IN_BYTES } from 'consts';

export const fileSize = (
    file: File | GetFile,
    maxSize = TEN_MB_IN_BYTES,
    acceptedFormats = ACCEPTED_IMAGE_FORMATS,
    t: (key: string, options?: Record<string, number | string>) => string
): boolean | string => {
    if ((file as GetFile).id) {
        return true;
    }

    if ((file as File).size > maxSize) {
        return t('image-size', {
            ns: 'common/errors',
            size: formatBytes(maxSize),
        });
    }

    const formats = acceptedFormats
        .split(', ')
        .map((item: string) => {
            const replaced = item.replace('.', '');
            return `image/${replaced}`;
        })
        .join(' ');

    if (!formats.includes((file as File).type)) {
        return t('image-format', {
            ns: 'common/errors',
            formats: acceptedFormats,
        });
    }

    return true;
};
