import React, { FC } from 'react';
import { useSpeakersTags } from 'contexts';
import { cellRenderMapper, initialColumns } from './mappers';
import { SpeakersTag, GetSpeakersTags } from 'types';
import { renderCell, getTableColumns } from 'utils';
import { PageBuilder } from 'containers';
import { PageBuilderKey } from 'enums';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

const SpeakersTags: FC = () => {
    const { speakersTags, getSpeakersTags, getExcel, deleteSpeakersTags } = useSpeakersTags();
    const { i18n } = useTranslation('common/shared');
    const navigate = useNavigate();

    return (
        <PageBuilder<SpeakersTag>
            pageKey={PageBuilderKey.SpeakersTags}
            data={speakersTags}
            getItems={(params) => getSpeakersTags(params as GetSpeakersTags)}
            deleteItems={deleteSpeakersTags}
            columns={getTableColumns<SpeakersTag>(
                initialColumns,
                cellRenderMapper,
                renderCell,
                i18n.getResourceBundle(i18n.language, 'common/shared')
            )}
            addItem={() => navigate('/speakers-tag')}
            updateItem={(speakersTag: SpeakersTag) => navigate(`/speakers-tag/${speakersTag.id}`)}
            hideBaseFilters
        />
    );
};

export default SpeakersTags;
