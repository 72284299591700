import React, { FC } from 'react';
import { InformationCard, Icon } from '@kl/components-v6';
import { useTranslation } from 'react-i18next';

const AccessDenied: FC = () => {
    const { t } = useTranslation('pages/access-denied');

    return (
        <InformationCard
            width={350}
            type={'horizontal'}
            title={t('title')}
            description={t('description')}
            leftSide={<Icon size={'medium'} name={'Accountcheck'} />}
        />
    );
};

export default AccessDenied;
