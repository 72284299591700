import { CellRenderType } from 'enums';
import { SpeakersTag } from 'types';

export const initialColumns: SpeakersTag = {
    id: '',
    creationDate: new Date(),
    name: '',
};

export const cellRenderMapper: Record<keyof SpeakersTag, CellRenderType> = {
    id: CellRenderType.TEXT,
    creationDate: CellRenderType.DATETIME,
    name: CellRenderType.TEXT,
};
