import { Participant, Committee, Sponsor } from 'types';
import { ControlRendererType } from 'enums';
import { RegisterOptions } from 'react-hook-form';
import { ACCEPTED_IMAGE_FORMATS, TEN_MB_IN_BYTES } from 'consts';
import { fileSize } from 'containers/form-builder/rules';
import { GetFile } from 'kl-b2c-ui-kit';
import { ControlRendererRepresentation } from 'types/form-builder/control-renderer-config';

export const committeeRendererTypes = (
    t: (key: string, options?: Record<string, number | string>) => string
): Record<keyof Omit<Committee, 'id' | 'conference'>, ControlRendererRepresentation> => ({
    firstName: {
        type: ControlRendererType.TextBox,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 200,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 200,
                }),
            },
        },
    },
    lastName: {
        type: ControlRendererType.TextBox,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 200,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 200,
                }),
            },
        },
    },
    number: {
        type: ControlRendererType.Number,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            validate: (number) => {
                if (number <= 0) {
                    return t('only-positive', {
                        ns: 'common/errors',
                    });
                }

                if (number % 1 !== 0) {
                    return t('only-natural', {
                        ns: 'common/errors',
                    });
                }
            },
        },
    },
    position: {
        type: ControlRendererType.TextBox,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 200,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 200,
                }),
            },
        },
    },
    twitter: {
        type: ControlRendererType.TextBox,
    },
    description: {
        type: ControlRendererType.Wysiwyg,
        rules: {
            maxLength: {
                value: 500,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 500,
                }),
            },
        },
    },
    file: {
        type: ControlRendererType.UploadImage,
        rules: {
            required: {
                value: true,
                message: t('required-image', {
                    ns: 'common/errors',
                }),
            },
            validate: (file: File | GetFile) => {
                return fileSize(file, TEN_MB_IN_BYTES, ACCEPTED_IMAGE_FORMATS, t);
            },
        },
        image: {
            size: TEN_MB_IN_BYTES,
            accept: '.jpg, .jpeg, .png',
        },
    },
    visible: {
        type: ControlRendererType.CheckBox,
    },
    creationDate: {
        type: ControlRendererType.DateTime,
    },
});
