import { useConferences, useProgramEvents, useProgramModerators, useProgramSessions, useToaster } from 'contexts';
import { FormBuilderKeys } from 'enums/form-builder-keys';
import React, { FC, useEffect, useState } from 'react';
import { Controller, FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Conference, ProgramEvent, ProgramModerator, ProgramSession as ProgramSessionModel } from 'types';
import { Heading, Loader, Select, Textbox } from '@kl/components-v6';
import { FormBuilder } from 'containers';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { ErrorMessage, FormRow } from 'containers/form-builder/styled';
import { FormRow as FormInner } from '../../form-builder/components';

const INITIAL_DATA: Omit<ProgramSessionModel, 'programEventIds' | 'conferenceId' | 'moderatorId'> = {
    id: '',
    creationDate: new Date(),
    name: '',
    description: '',
    programEvents: [],
    visible: true,
    startTime: '',
    endTime: '',
    location: '',
    date: new Date().toString(),
};

const ProgramSession: FC = () => {
    const { getProgramSession, programSession, updateProgramSession, addProgramSession } = useProgramSessions();
    const { conferences, getConferences } = useConferences();
    const { programEvents, getProgramEvents } = useProgramEvents();
    const { programModerators, getProgramModerators } = useProgramModerators();
    const { id } = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const { t } = useTranslation(['pages/program-sessions', 'common/shared']);
    const { setToaster } = useToaster();

    const methods = useForm<ProgramSessionModel>();

    const {
        control,
        formState: { errors },
    } = methods;

    const onSubmit = async (data: FieldValues) => {
        setLoading(true);
        try {
            const currentTimezoneOffset = new Date().getTimezoneOffset();
            const dateStr = new Date(new Date(data.date).getTime() - currentTimezoneOffset * 60000).toISOString();
            if (id) {
                await updateProgramSession({
                    ...data,
                    id,
                    date: dateStr,
                } as ProgramSessionModel);
            } else {
                await addProgramSession({
                    ...data,
                    date: dateStr,
                } as ProgramSessionModel);
            }
            navigate('/program/sessions');
        } catch (e: unknown) {
            setToaster({
                message: (e as AxiosError).message ?? t('somethingWrong', { ns: 'common/shared' }),
                type: 'error',
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getConferences({ page: 0, size: 300 });
        getProgramModerators({ page: 0, size: 300 });
        getProgramEvents({ page: 0, size: 300, isVisible: null, conferenceId: null });
        if (id) {
            getProgramSession(id);
        }
    }, []);

    if (id && (!programSession || !programEvents || !conferences || !programModerators)) {
        return <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />;
    }

    return (
        <>
            <Heading type={'H2'}>{id ? t('update-programSession') : t('add-programSession')}</Heading>
            <FormProvider {...methods}>
                <FormBuilder<
                    Omit<
                        ProgramSessionModel,
                        'id' | 'creationDate' | 'programEventIds' | 'conferenceId' | 'moderatorId'
                    >
                >
                    data={programSession || INITIAL_DATA}
                    formKey={FormBuilderKeys.ProgramSession}
                    loading={loading}
                    isFormEmpty={!id}
                    submit={onSubmit}
                    cancel={() => navigate('/program/sessions')}
                >
                    <FormRow>
                        <span>{t('Events', { ns: 'common/shared' })}</span>
                        <Controller
                            name={'programEventIds'}
                            rules={{ required: false }}
                            control={control}
                            defaultValue={programSession?.programEvents?.map((event) => event.id)}
                            render={({ field: { onChange } }) => (
                                <Select
                                    mode={'multiple'}
                                    showSearch
                                    onChange={onChange}
                                    defaultValue={programSession?.programEvents?.map((event) => event.id)}
                                    options={programEvents.items?.map((option: ProgramEvent) => ({
                                        label: option.name,
                                        value: option.id,
                                    }))}
                                />
                            )}
                        />
                        {errors?.programEventIds && (
                            <ErrorMessage>{t('required-field', { ns: 'common/errors' })}</ErrorMessage>
                        )}
                    </FormRow>
                    <FormRow>
                        <span>{t('moderator', { ns: 'common/shared' })}</span>
                        <Controller
                            name={'moderatorId'}
                            control={control}
                            defaultValue={programSession?.moderator?.id}
                            render={({ field: { onChange, value } }) => (
                                <Select
                                    showSearch
                                    onChange={onChange}
                                    defaultValue={programSession?.moderator?.id}
                                    options={programModerators.items?.map((option: ProgramModerator) => ({
                                        label: option.firstName + ' ' + option.lastName,
                                        value: option.id,
                                    }))}
                                />
                            )}
                        />
                        {errors?.moderatorId && (
                            <ErrorMessage>{t('required-field', { ns: 'common/errors' })}</ErrorMessage>
                        )}
                    </FormRow>
                    <FormRow>
                        <span>{t('conference', { ns: 'common/shared' })}*</span>
                        <Controller
                            name={'conferenceId'}
                            rules={{ required: true }}
                            control={control}
                            defaultValue={programSession?.conference?.id}
                            render={({ field: { onChange, value } }) => (
                                <Select
                                    showSearch
                                    onChange={onChange}
                                    defaultValue={programSession?.conference?.id}
                                    options={conferences.items?.map((option: Conference) => ({
                                        label: option.name,
                                        value: option.id,
                                    }))}
                                />
                            )}
                        />
                        {errors?.conferenceId && (
                            <ErrorMessage>{t('required-field', { ns: 'common/errors' })}</ErrorMessage>
                        )}
                    </FormRow>
                </FormBuilder>
            </FormProvider>
        </>
    );
};

export default ProgramSession;
