import React from 'react';
import { CellRenderType } from 'enums';
import { Participant, Sponsor } from 'types';

export const initialColumns: Omit<Sponsor, 'id'> = {
    creationDate: new Date(),
    agreementId: '',
    firstName: '',
    lastName: '',
    company: '',
    email: '',
    comments: '',
    phone: '',
    conference: {
        id: '',
        name: '',
        creationDate: new Date(),
        isCurrent: true,
    },
    utmTerm: '',
    utmMedium: '',
    utmCampaign: '',
    utmSource: '',
    utmContent: '',
};

export const cellRenderMapper: Record<keyof Omit<Sponsor, 'id'>, CellRenderType> = {
    creationDate: CellRenderType.DATETIME,
    firstName: CellRenderType.TEXT,
    lastName: CellRenderType.TEXT,
    company: CellRenderType.TEXT,
    email: CellRenderType.TEXT,
    comments: CellRenderType.TEXT,
    agreementId: CellRenderType.AGREEMENT_ID,
    phone: CellRenderType.TEXT,
    conference: CellRenderType.CONFERENCE,
    utmTerm: CellRenderType.TEXT,
    utmMedium: CellRenderType.TEXT,
    utmCampaign: CellRenderType.TEXT,
    utmSource: CellRenderType.TEXT,
    utmContent: CellRenderType.TEXT,
};
