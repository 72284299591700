import { CellRenderType } from 'enums';
import { ProgramSession } from 'types';

export const initialColumns: Omit<
    ProgramSession,
    | 'moderator'
    | 'moderatorId'
    | 'number'
    | 'programEvents'
    | 'programEventIds'
    | 'conferenceId'
    | 'startTime'
    | 'endTime'
    | 'location'
> = {
    id: '',
    creationDate: new Date(),
    name: '',
    date: '',
    description: '',
    visible: true,
    conference: undefined,
};

export const cellRenderMapper: Record<
    keyof Omit<
        ProgramSession,
        | 'moderator'
        | 'moderatorId'
        | 'number'
        | 'programEvents'
        | 'programEventIds'
        | 'conferenceId'
        | 'startTime'
        | 'endTime'
        | 'location'
        | ''
    >,
    CellRenderType
> = {
    id: CellRenderType.TEXT,
    creationDate: CellRenderType.DATETIME,
    name: CellRenderType.TEXT,
    description: CellRenderType.MARKUP,
    visible: CellRenderType.BOOLEAN,
    conference: CellRenderType.CONFERENCE,
    date: CellRenderType.DATE_FIX_TIMEZONE,
};
