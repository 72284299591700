import { PageBuilderFilterType, GetAgreements } from 'types';
import { AgreementSelected, AgreementsChapter, FilterType, IsVisible } from 'enums';

export type AvailableType = AgreementsChapter | AgreementSelected | IsVisible;

interface ReturnType {
    defaultValue: AvailableType;
    options: {
        value: AvailableType;
        label: string;
    }[];
}

export const filterToDropdown = (key: FilterType, filters: PageBuilderFilterType): ReturnType => {
    switch (key) {
        case FilterType.IsMarketing: {
            return {
                defaultValue: AgreementSelected.All,
                options: Object.keys(AgreementSelected)
                    .filter((el) => isNaN(Number(el)))
                    .map((key) => ({
                        value: AgreementSelected[key as keyof typeof AgreementSelected],
                        label: key,
                    })),
            };
        }
        case FilterType.Chapter: {
            return {
                defaultValue: (filters as GetAgreements).chapter || AgreementsChapter.All,
                options: Object.keys(AgreementsChapter)
                    .filter((el) => isNaN(Number(el)))
                    .map((key) => ({
                        value: AgreementsChapter[key as keyof typeof AgreementsChapter],
                        label: key,
                    })),
            };
        }
        case FilterType.IsVisible: {
            return {
                defaultValue: IsVisible.All,
                options: Object.keys(IsVisible)
                    .filter((el) => isNaN(Number(el)))
                    .map((key) => ({
                        value: IsVisible[key as keyof typeof IsVisible],
                        label: key,
                    })),
            };
        }
        default: {
            throw new Error(`No filter type for ${key} has been found, consider to add one`);
        }
    }
};
