import { IApiService, IConferenceApi } from 'interfaces';
import { Conference, GetConferenceRequest, HttpResponse } from 'types';
import BaseApi from './base-api.service';
import { ApiBaseKeys } from 'enums/api-base-keys';

class ConferenceApi extends BaseApi<GetConferenceRequest, Conference> implements IConferenceApi {
    constructor(apiService: IApiService) {
        super(apiService, ApiBaseKeys.Conferences);
    }

    public getCurrent = (): Promise<HttpResponse<Conference | null>> => {
        return this.apiService.request<Conference | null>({
            method: 'GET',
            url: '/Conference/GetCurrentConference',
        });
    };
}

export default ConferenceApi;
