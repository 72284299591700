import React, { createContext, FC, Key, PropsWithChildren, useContext, useMemo, useState, useEffect } from 'react';
import { getApiService, getConferencesApiService, getStorageService } from 'services';
import { downloadFileFromBase64, PaginationOutput } from 'kl-b2c-ui-kit';
import { Conference, GetConferenceRequest, ConferenceAddOrUpdate, HttpResponse } from 'types';

interface ConferenceContext {
    conferences: PaginationOutput<Conference>;
    conference: Omit<Conference, 'id' | 'creationDate'> | null;
    loading: boolean;
    currentConference: Conference | null;
    getConference: (id: string) => void;
    getConferences: (params: GetConferenceRequest) => Promise<void>;
    getExcel: (params: Omit<GetConferenceRequest, 'page' | 'size'>) => Promise<void>;
    deleteConferences: (ids: Key[]) => Promise<HttpResponse<string>>;
    addConference: (data: Omit<ConferenceAddOrUpdate, 'id'>) => Promise<void>;
    updateConference: (data: ConferenceAddOrUpdate) => void;
    getCurrentConference: () => Promise<Conference | null>;
    clearConference: () => void;
}

const ConferenceContext = createContext<ConferenceContext>({} as ConferenceContext);

const ConferenceProvider: FC<PropsWithChildren> = ({ children }) => {
    const storageService = getStorageService();
    const [conference, setConference] = useState<Omit<Conference, 'id' | 'creationDate'> | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [currentConference, setCurrentConference] = useState<Conference | null>(null);
    const [conferences, setConferences] = useState<PaginationOutput<Conference>>({
        count: 0,
        items: null,
    });
    const apiService = getApiService(storageService);
    const conferenceApiService = getConferencesApiService(apiService);

    const getConference = async (id: string) => {
        try {
            setLoading(true);
            const conf = await conferenceApiService.getItem(id);
            setConference(conf.data);
        } finally {
            setLoading(false);
        }
    };

    const addConference = async (data: Omit<ConferenceAddOrUpdate, 'id'>) => {
        try {
            setLoading(true);
            await conferenceApiService.add(data);
        } finally {
            setLoading(false);
        }
    };

    const updateConference = async (data: ConferenceAddOrUpdate) => {
        try {
            setLoading(true);
            const event = await conferenceApiService.update(data);
            if (event.status === 207) throw new Error(event.data as unknown as string);
            setConference(event.data);
        } finally {
            setLoading(false);
        }
    };

    const getConferences = async (params: GetConferenceRequest) => {
        try {
            setLoading(true);
            const conferences = await conferenceApiService.getItems({
                ...params,
            });
            setConferences(conferences.data);
        } finally {
            setLoading(false);
        }
    };

    const deleteConferences = async (ids: Key[]) => {
        try {
            setLoading(true);
            const response = await conferenceApiService.deleteMany(ids);
            return response;
        } finally {
            setLoading(false);
        }
    };

    const getExcel = async (params: Omit<GetConferenceRequest, 'page' | 'size'>) => {
        try {
            setLoading(true);
            const base64 = await conferenceApiService.getExcel({
                ...params,
            });
            downloadFileFromBase64(base64.data, 'xlsx', 'conferences');
        } finally {
            setLoading(false);
        }
    };

    const getCurrentConference = async () => {
        const response = await conferenceApiService.getCurrent();
        setCurrentConference(response?.data);
        return response?.data;
    };

    const memoValue = useMemo(
        () => ({
            conferences,
            conference,
            loading,
            currentConference,
            getConference,
            addConference,
            updateConference,
            getConferences,
            getExcel,
            deleteConferences,
            getCurrentConference,
            clearConference: () => setConference(null),
        }),
        [getConferences, conferences]
    );

    return <ConferenceContext.Provider value={memoValue}>{children}</ConferenceContext.Provider>;
};

export const useConferences = () => useContext(ConferenceContext);

export default ConferenceProvider;
