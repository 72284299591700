import { ControlRendererType } from 'enums';
import { GlobalSiteConfig } from 'types';
import { ControlRendererRepresentation } from 'types/form-builder/control-renderer-config';

export const configRendererTypes = (
    t: (key: string, options?: Record<string, number | string>) => string
): Record<keyof GlobalSiteConfig, ControlRendererRepresentation> => ({
    isOpened: {
        type: ControlRendererType.Toggle,
    },
    updateDate: {
        type: ControlRendererType.DateTime,
    },
});
