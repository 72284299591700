import React, { useEffect } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Agreement, Conference, UpdateMarketing } from 'types';
import { Heading, Loader } from '@kl/components-v6';
import { FormBuilder } from '..';
import { FormBuilderKeys } from 'enums/form-builder-keys';
import { useAgreements } from 'contexts';

type EditType = Omit<
    Agreement,
    | 'id'
    | 'creationDate'
    | 'chapter'
    | 'email'
    | 'privacyPolicyIsSelected'
    | 'marketingCheckBoxText'
    | 'touCheckBoxText'
    | 'touText'
    | 'privacyPolicyCheckBoxText'
    | 'privacyPolicyText'
>;

const INITIAL_DATA: EditType = {
    marketingIsSelected: false,
};

const Conferences = () => {
    const { updateAgreement, getAgreement, agreement, loading } = useAgreements();
    const { id } = useParams();
    const navigate = useNavigate();

    const { t } = useTranslation('pages/agreements');

    const methods = useForm<Omit<Conference, 'id' | 'creationDate'>>();

    const onSubmit = async (data: FieldValues) => {
        if (id) {
            const result = await updateAgreement({ ...data, agreementId: id } as UpdateMarketing);
            if (result.status !== 200) throw new Error(t('update-agreement-error'));
        }
    };

    useEffect(() => {
        if (id) {
            getAgreement(id);
        }
    }, [id]);

    if (id && loading) {
        return <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />;
    }

    return (
        <>
            <Heading type={'H2'}>{id ? t('update-agreements') : ''}</Heading>
            {!loading && (
                <FormProvider {...methods}>
                    <FormBuilder<EditType>
                        data={id && agreement ? agreement : INITIAL_DATA}
                        submit={onSubmit}
                        cancel={() => navigate('/agreements')}
                        formKey={FormBuilderKeys.Agreement}
                        isFormEmpty={!id}
                        loading={loading}
                    />
                </FormProvider>
            )}
        </>
    );
};

export default Conferences;
