import { CellRenderType } from 'enums';
import { ProgramEvent } from 'types';

export const initialColumns: Omit<
    ProgramEvent,
    'speakers' | 'speakerIds' | 'tags' | 'tagIds' | 'number' | 'conferenceId' | 'location'
> = {
    id: '',
    creationDate: new Date(),
    name: '',
    description: '',
    startTime: '',
    endTime: '',
    visible: true,
    conference: undefined,
};

export const cellRenderMapper: Record<
    keyof Omit<ProgramEvent, 'speakers' | 'speakerIds' | 'tags' | 'tagIds' | 'number' | 'conferenceId' | 'location'>,
    CellRenderType
> = {
    id: CellRenderType.TEXT,
    creationDate: CellRenderType.DATETIME,
    name: CellRenderType.TEXT,
    description: CellRenderType.MARKUP,
    startTime: CellRenderType.TEXT,
    endTime: CellRenderType.TEXT,
    visible: CellRenderType.BOOLEAN,
    conference: CellRenderType.CONFERENCE,
};
