import { Agreement } from 'types';
import { ControlRendererType } from 'enums';
import { ControlRendererRepresentation } from 'types/form-builder/control-renderer-config';

type EditType = Omit<
    Agreement,
    | 'id'
    | 'creationDate'
    | 'chapter'
    | 'email'
    | 'privacyPolicyIsSelected'
    | 'marketingCheckBoxText'
    | 'touCheckBoxText'
    | 'touText'
    | 'privacyPolicyCheckBoxText'
    | 'privacyPolicyText'
>;

export const agreementRendererTypes = (
    t: (key: string, options?: Record<string, number | string>) => string
): Record<keyof EditType, ControlRendererRepresentation> => ({
    marketingIsSelected: {
        type: ControlRendererType.CheckBox,
    },
});
