import React, { FC } from 'react';
import { useCommittees } from 'contexts';
import { cellRenderMapper, initialColumns } from './mappers';
import { Committee, GetCommittees } from 'types';
import { renderCell, getTableColumns } from 'utils';
import { PageBuilder } from 'containers';
import { PageBuilderKey } from 'enums';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

const Committees: FC = () => {
    const { committees, getCommittees, deleteCommittees } = useCommittees();
    const { i18n } = useTranslation('common/shared');
    const navigate = useNavigate();

    return (
        <PageBuilder<Committee>
            pageKey={PageBuilderKey.Committees}
            data={committees}
            getItems={(params) => getCommittees(params as GetCommittees)}
            deleteItems={deleteCommittees}
            columns={getTableColumns<Omit<Committee, 'description' | 'number' | 'file'>>(
                initialColumns,
                cellRenderMapper,
                renderCell,
                i18n.getResourceBundle(i18n.language, 'common/shared')
            )}
            addItem={() => navigate('/committee')}
            updateItem={(committee: Committee) => navigate(`/committee/${committee.id}`)}
            hideBaseFilters
        />
    );
};

export default Committees;
