import { Participant, Speaker, Sponsor } from 'types';
import { ControlRendererType } from 'enums';
import { RegisterOptions } from 'react-hook-form';
import { ControlRendererRepresentation } from 'types/form-builder/control-renderer-config';

export const speakerRendererTypes = (
    t: (key: string, options?: Record<string, number | string>) => string
): Record<
    keyof Omit<Speaker, 'id'> | keyof Omit<Sponsor, 'id' | 'file'> | keyof Omit<Participant, 'id' | 'file'>,
    ControlRendererRepresentation
> => ({
    firstName: {
        type: ControlRendererType.Text,
    },
    lastName: {
        type: ControlRendererType.Text,
    },
    agreementId: {
        type: ControlRendererType.Link,
    },
    comments: {
        type: ControlRendererType.Text,
    },
    company: {
        type: ControlRendererType.Text,
    },
    conference: {
        type: ControlRendererType.Text,
    },
    creationDate: {
        type: ControlRendererType.DateTime,
    },
    email: {
        type: ControlRendererType.Text,
    },
    file: {
        type: ControlRendererType.UploadImage,
        readonly: true,
    },
    phone: {
        type: ControlRendererType.Text,
    },
    position: {
        type: ControlRendererType.Text,
    },
    topic: {
        type: ControlRendererType.Text,
    },
    utmCampaign: {
        type: ControlRendererType.Text,
    },
    utmContent: {
        type: ControlRendererType.Text,
    },
    utmMedium: {
        type: ControlRendererType.Text,
    },
    utmSource: {
        type: ControlRendererType.Text,
    },
    utmTerm: {
        type: ControlRendererType.Text,
    },
});
