import React from 'react';
import { CellRenderType } from 'enums';
import { MarketingRequest } from 'types/index';

export const initialColumns: Omit<MarketingRequest, 'id'> = {
    creationDate: new Date(),
    agreementId: '',
    email: '',
    utmCampaign: '',
    utmContent: '',
    utmMedium: '',
    utmSource: '',
    utmTerm: '',
};

export const cellRenderMapper: Record<keyof Omit<MarketingRequest, 'id'>, CellRenderType> = {
    creationDate: CellRenderType.DATETIME,
    agreementId: CellRenderType.AGREEMENT_ID,
    email: CellRenderType.TEXT,
    utmCampaign: CellRenderType.TEXT,
    utmContent: CellRenderType.TEXT,
    utmMedium: CellRenderType.TEXT,
    utmSource: CellRenderType.TEXT,
    utmTerm: CellRenderType.TEXT,
};
