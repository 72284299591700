import { Tabs } from '@kl/components-v6';
import React from 'react';
import { useNavigate, Outlet } from 'react-router-dom';

const elements = [
    {
        tabTitle: 'General',
        path: '/content/general',
    },
    {
        tabTitle: 'Committees',
        path: '/content/committees',
    },
    {
        tabTitle: 'Speakers Tags',
        path: '/content/speakers-tags',
    },
    {
        tabTitle: 'Speakers',
        path: '/content/speakers',
    },
    {
        tabTitle: 'Sponsors',
        path: '/content/sponsors',
    },
];

const ContentBase = () => {
    const navigate = useNavigate();

    return (
        <>
            <Tabs activeKey={window.location.pathname} onChange={(path) => navigate(path)}>
                {elements.map((element) => (
                    <Tabs.TabPane tab={element.tabTitle} key={element.path} />
                ))}
            </Tabs>

            <Outlet />
        </>
    );
};

export default ContentBase;
