import React, { FC, PropsWithChildren } from 'react';
import { Icon, Button } from '@kl/components-v6';
import { UserInfoContainer } from './styled';
import { useAuth } from 'contexts/auth.context';

interface SiderUserInfoItemProps {
    collapsed: boolean;
}

const SiderUserInfo: FC<PropsWithChildren<SiderUserInfoItemProps>> = ({ collapsed }) => {
    const { userInfo, signOut } = useAuth();

    if (!userInfo) return null;

    return (
        <UserInfoContainer collapsed={collapsed}>
            <Button
                onClick={signOut}
                size={'large'}
                iconAfter={
                    // @ts-ignore
                    <Icon key={'signOutIcon'} size={'medium'} name={'SignOut'} themedColor={'primary-invert'} />
                }
                mode="primaryBlack"
                text={userInfo.fullName}
            />
        </UserInfoContainer>
    );
};

export default SiderUserInfo;
