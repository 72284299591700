import { Conference } from 'types';
import { ControlRendererType } from 'enums';
import { RegisterOptions } from 'react-hook-form';
import { ControlRendererRepresentation } from 'types/form-builder/control-renderer-config';

export const conferenceRendererTypes = (
    t: (key: string, options?: Record<string, number | string>) => string
): Record<keyof Omit<Conference, 'id' | 'creationDate'>, ControlRendererRepresentation> => ({
    name: {
        type: ControlRendererType.TextBox,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 200,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 200,
                }),
            },
        },
    },
    isCurrent: {
        type: ControlRendererType.CheckBox,
    },
});
