export enum FormBuilderKeys {
    Config = 'Config',
    Conference = 'Conference',
    Agreement = 'Agreement',
    Speaker = 'Speaker',
    Sponsor = 'Sponsor',
    Participant = 'Participant',
    Committee = 'Committee',
    SpeakersTag = 'SpeakersTag',
    SpeakersContent = 'SpeakersContent',
    SponsorsContent = 'SponsorsContent',
    ProgramTag = 'ProgramTag',
    ProgramModerator = 'ProgramModerator',
    ProgramEvent = 'ProgramEvent',
    ProgramSession = 'ProgramSession',
}
