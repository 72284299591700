import React, { FC } from 'react';
import { useProgramTags } from 'contexts';
import { cellRenderMapper, initialColumns } from './columns';
import { ProgramTag, GetProgramTags } from 'types';
import { renderCell, getTableColumns } from 'utils';
import { PageBuilder } from 'containers';
import { PageBuilderKey } from 'enums';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

const ProgramTags: FC = () => {
    const { programTags, getProgramTags, getExcel, deleteProgramTags } = useProgramTags();
    const { i18n } = useTranslation('common/shared');
    const navigate = useNavigate();
    console.log(
        getTableColumns<ProgramTag>(
            initialColumns,
            cellRenderMapper,
            renderCell,
            i18n.getResourceBundle(i18n.language, 'common/shared')
        )
    );
    return (
        <PageBuilder<ProgramTag>
            pageKey={PageBuilderKey.ProgramTags}
            data={programTags}
            getItems={(params) => getProgramTags(params as GetProgramTags)}
            deleteItems={deleteProgramTags}
            columns={getTableColumns<ProgramTag>(
                initialColumns,
                cellRenderMapper,
                renderCell,
                i18n.getResourceBundle(i18n.language, 'common/shared')
            )}
            addItem={() => navigate('/program/tag/')}
            updateItem={(programTag: ProgramTag) => navigate(`/program/tag/${programTag.id}`)}
            hideBaseFilters
        />
    );
};

export default ProgramTags;
