import React, { useEffect, useState } from 'react';
import { useConferences } from 'contexts/conference.context';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Conference } from 'types';
import { Heading, Loader } from '@kl/components-v6';
import { FormBuilder } from '..';
import { FormBuilderKeys } from 'enums/form-builder-keys';
import { useToaster } from 'contexts';

const INITIAL_DATA: Omit<Conference, 'id' | 'creationDate'> = {
    name: '',
    isCurrent: false,
};

const Conferences = () => {
    const { getConference, conference, updateConference, addConference, loading, clearConference } = useConferences();
    const { id } = useParams();
    const navigate = useNavigate();
    const { setToaster } = useToaster();

    const { t } = useTranslation('pages/conferences');

    const methods = useForm<Omit<Conference, 'id' | 'creationDate'>>();

    const onSubmit = async (data: FieldValues) => {
        if (id) {
            await updateConference({ ...data, id } as Conference);
            setToaster({
                type: 'success',
                message: t('update-conference-success'),
            });
        } else {
            await addConference(data as Conference);
            navigate('/conferences');
        }
    };

    useEffect(() => {
        if (id) {
            getConference(id);
        }
    }, [id]);

    useEffect(() => {
        return () => {
            clearConference();
        };
    }, []);

    if (id && !conference) {
        return <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />;
    }

    return (
        <>
            <Heading type={'H2'}>{id ? t('update-conference') : t('add-conference')}</Heading>
            <FormProvider {...methods}>
                <FormBuilder<Omit<Conference, 'id' | 'creationDate'>>
                    data={id && conference ? conference : INITIAL_DATA}
                    submit={onSubmit}
                    cancel={() => navigate('/conferences')}
                    formKey={FormBuilderKeys.Conference}
                    isFormEmpty={!id}
                    loading={loading}
                />
            </FormProvider>
        </>
    );
};

export default Conferences;
