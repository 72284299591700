import { useCommittees, useToaster } from 'contexts';
import { FormBuilderKeys } from 'enums/form-builder-keys';
import React, { FC, useEffect, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Committee as CommitteeModel } from 'types';
import { Heading, Loader } from '@kl/components-v6';
import { FormBuilder } from 'containers';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';

const INITIAL_DATA: Omit<CommitteeModel, 'id' | 'creationDate'> = {
    firstName: '',
    lastName: '',
    position: '',
    twitter: '',
    description: '',
    visible: false,
    number: 1,
    file: null,
};

const Committee: FC = () => {
    const { getCommittee, committee, updateCommittee, addCommittee } = useCommittees();
    const { id } = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const { t } = useTranslation(['pages/committee', 'common/shared']);
    const { setToaster } = useToaster();

    const methods = useForm<CommitteeModel>();

    const onSubmit = async (data: FieldValues) => {
        setLoading(true);
        try {
            if (id) {
                await updateCommittee({ ...data, id } as CommitteeModel);
            } else {
                await addCommittee(data as CommitteeModel);
            }
            navigate('/content/committees');
        } catch (e: unknown) {
            setToaster({
                message: (e as AxiosError).message ?? t('somethingWrong', { ns: 'common/shared' }),
                type: 'error',
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (id) {
            getCommittee(id);
        }
    }, []);

    if (id && !committee) {
        return <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />;
    }

    return (
        <>
            <Heading type={'H2'}>{id ? t('update-committee') : t('add-committee')}</Heading>
            <FormProvider {...methods}>
                <FormBuilder<Omit<CommitteeModel, 'id' | 'creationDate'>>
                    data={committee || INITIAL_DATA}
                    formKey={FormBuilderKeys.Committee}
                    loading={loading}
                    isFormEmpty={!id}
                    submit={onSubmit}
                    cancel={() => navigate('/content/committees')}
                />
            </FormProvider>
        </>
    );
};

export default Committee;
