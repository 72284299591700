import { CellRenderType } from 'enums';
import { Committee } from 'types';

export const initialColumns: Omit<Committee, 'description' | 'number' | 'file'> = {
    id: '',
    creationDate: new Date(),
    visible: false,
    firstName: '',
    lastName: '',
    position: '',
    twitter: '',
};

export const cellRenderMapper: Record<keyof Omit<Committee, 'description' | 'number' | 'file'>, CellRenderType> = {
    id: CellRenderType.TEXT,
    creationDate: CellRenderType.DATETIME,
    visible: CellRenderType.BOOLEAN,
    firstName: CellRenderType.TEXT,
    lastName: CellRenderType.TEXT,
    position: CellRenderType.TEXT,
    twitter: CellRenderType.TEXT,
};
