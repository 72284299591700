import { InputMask } from '@react-input/mask';
import styled from 'styled-components';

export const FormWrapper = styled.form`
    padding: 20px 0;
    max-width: 750px;

    &.disabled {
        opacity: 0.6;
        pointer-events: none;
    }
`;

export const FormRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 0 0 25px 0;
    gap: 20px;
    position: relative;

    & > span {
        width: 150px;
        flex-shrink: 0;
    }
`;

export const TimeInput = styled(InputMask)`
    border-radius: 8px;
`;
