import React, { createContext, FC, Key, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { getApiService, getMarketingRequestsApiService, getStorageService } from 'services';
import { GetMarketingRequests, HttpResponse, MarketingRequest } from 'types';
import { PaginationOutput, downloadFileFromBase64 } from 'kl-b2c-ui-kit';

interface MarketingRequestsContext {
    marketingRequests: PaginationOutput<MarketingRequest>;
    getMarketingRequests: (params: GetMarketingRequests) => Promise<void>;
    getExcel: (params: Omit<GetMarketingRequests, 'page' | 'size'>) => Promise<void>;
    deleteMarketingRequests: (ids: Key[]) => Promise<HttpResponse<string>>;
}

const MarketingRequestsContext = createContext<MarketingRequestsContext>({} as MarketingRequestsContext);

const MarketingRequestsProvider: FC<PropsWithChildren> = ({ children }) => {
    const storageService = getStorageService();
    const [marketingRequests, setMarketingRequests] = useState<PaginationOutput<MarketingRequest>>({
        count: 0,
        items: null,
    });
    const apiService = getApiService(storageService);
    const marketingRequestsApiService = getMarketingRequestsApiService(apiService);

    const getMarketingRequests = async (params: GetMarketingRequests) => {
        const marketingRequests = await marketingRequestsApiService.getItems(params);
        setMarketingRequests(marketingRequests.data);
    };

    const deleteMarketingRequests = async (ids: Key[]) => {
        return await marketingRequestsApiService.deleteMany(ids);
    };

    const getExcel = async (params: Omit<GetMarketingRequests, 'page' | 'size'>) => {
        const base64 = await marketingRequestsApiService.getExcel(params);
        downloadFileFromBase64(base64.data, 'xlsx', 'marketingRequests');
    };

    const memoValue = useMemo(
        () => ({
            getMarketingRequests,
            marketingRequests,
            getExcel,
            deleteMarketingRequests,
        }),
        [getMarketingRequests, marketingRequests]
    );

    return <MarketingRequestsContext.Provider value={memoValue}>{children}</MarketingRequestsContext.Provider>;
};

export const useMarketingRequests = () => useContext(MarketingRequestsContext);

export default MarketingRequestsProvider;
