import React, { createContext, FC, Key, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { getApiService, getStorageService } from 'services';
import { GetSponsors, HttpResponse, Sponsor } from 'types';
import { PaginationOutput, downloadFileFromBase64 } from 'kl-b2c-ui-kit';
import { getSponsorsApiService } from 'services/api.factory';

interface SponsorsContext {
    sponsors: PaginationOutput<Sponsor>;
    getSponsors: (params: GetSponsors) => Promise<void>;
    getExcel: (params: Omit<GetSponsors, 'page' | 'size'>) => Promise<void>;
    deleteSponsors: (ids: Key[]) => Promise<HttpResponse<string>>;
    getSponsor: (id: string) => Promise<Sponsor>;
}

const SponsorsContext = createContext<SponsorsContext>({} as SponsorsContext);

const SponsorsProvider: FC<PropsWithChildren> = ({ children }) => {
    const storageService = getStorageService();
    const [sponsors, setSponsors] = useState<PaginationOutput<Sponsor>>({
        count: 0,
        items: null,
    });
    const apiService = getApiService(storageService);
    const sponsorsApiService = getSponsorsApiService(apiService);

    const getSponsors = async (params: GetSponsors) => {
        const sponsors = await sponsorsApiService.getItems(params);
        setSponsors(sponsors.data);
    };

    const getSponsor = async (id: string) => {
        const sponsor = await sponsorsApiService.getItem(id);
        return sponsor.data;
    };

    const deleteSponsors = async (ids: Key[]) => {
        return await sponsorsApiService.deleteMany(ids);
    };

    const getExcel = async (params: Omit<GetSponsors, 'page' | 'size'>) => {
        const base64 = await sponsorsApiService.getExcel(params);
        downloadFileFromBase64(base64.data, 'xlsx', 'sponsors');
    };

    const memoValue = useMemo(
        () => ({
            getSponsors,
            sponsors,
            getExcel,
            deleteSponsors,
            getSponsor,
        }),
        [getSponsors, sponsors]
    );

    return <SponsorsContext.Provider value={memoValue}>{children}</SponsorsContext.Provider>;
};

export const useSponsors = () => useContext(SponsorsContext);

export default SponsorsProvider;
