import React, { createContext, FC, Key, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { getApiService, getParticipantsApiService, getStorageService } from 'services';
import { GetParticipants, HttpResponse, Participant } from 'types';
import { PaginationOutput, downloadFileFromBase64 } from 'kl-b2c-ui-kit';

interface ParticipantsContext {
    participants: PaginationOutput<Participant>;
    getParticipants: (params: GetParticipants) => Promise<void>;
    getExcel: (params: Omit<GetParticipants, 'page' | 'size'>) => Promise<void>;
    deleteParticipants: (ids: Key[]) => Promise<HttpResponse<string>>;
    getParticipant: (id: string) => Promise<Participant>;
}

const ParticipantsContext = createContext<ParticipantsContext>({} as ParticipantsContext);

const ParticipantsProvider: FC<PropsWithChildren> = ({ children }) => {
    const storageService = getStorageService();
    const [participants, setParticipants] = useState<PaginationOutput<Participant>>({
        count: 0,
        items: null,
    });
    const apiService = getApiService(storageService);
    const participantsApiService = getParticipantsApiService(apiService);

    const getParticipants = async (params: GetParticipants) => {
        const participants = await participantsApiService.getItems(params);
        setParticipants(participants.data);
    };

    const deleteParticipants = async (ids: Key[]) => {
        return await participantsApiService.deleteMany(ids);
    };

    const getExcel = async (params: Omit<GetParticipants, 'page' | 'size'>) => {
        const base64 = await participantsApiService.getExcel(params);
        downloadFileFromBase64(base64.data, 'xlsx', 'participants');
    };

    const getParticipant = async (id: string) => {
        const participant = await participantsApiService.getItem(id);
        return participant.data;
    };

    const memoValue = useMemo(
        () => ({
            getParticipants,
            participants,
            getExcel,
            deleteParticipants,
            getParticipant,
        }),
        [getParticipants, participants]
    );

    return <ParticipantsContext.Provider value={memoValue}>{children}</ParticipantsContext.Provider>;
};

export const useParticipants = () => useContext(ParticipantsContext);

export default ParticipantsProvider;
