import React from 'react';
import { useConferences } from 'contexts';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Conference, GetConferenceRequest } from 'types/conferences';
import { PageBuilder } from 'containers';
import { PageBuilderKey } from 'enums';
import { renderCell, getTableColumns } from 'utils';
import { cellRenderMapper, initialColumns } from './mappers';

const Conferences = () => {
    const { conferences, getConferences, getExcel, deleteConferences } = useConferences();
    const { i18n } = useTranslation('pages/conferences');
    const navigate = useNavigate();

    return (
        <PageBuilder<Conference>
            pageKey={PageBuilderKey.Conferences}
            data={conferences}
            getItems={(params) => getConferences(params as GetConferenceRequest)}
            getExcel={(params) => getExcel(params as Omit<GetConferenceRequest, 'page' | 'size'>)}
            deleteItems={deleteConferences}
            columns={getTableColumns<Conference>(
                initialColumns,
                cellRenderMapper,
                renderCell,
                i18n.getResourceBundle(i18n.language, 'pages/conferences')
            )}
            addItem={() => navigate('/conference')}
            updateItem={(conference: Conference) => navigate(`/conference/${conference.id}`)}
        />
    );
};

export default Conferences;
