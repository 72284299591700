import { useSponsors } from 'contexts';
import { FormBuilderKeys } from 'enums/form-builder-keys';
import { t } from 'i18next';
import { omit } from 'kl-b2c-ui-kit';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Sponsor as SponsorModel } from 'types';
import { FormBuilder } from '..';
import { Loader } from '@kl/components-v6';

const Sponsor: FC = () => {
    const { getSponsor } = useSponsors();
    const { id } = useParams();
    const [Sponsor, setSponsor] = useState<SponsorModel | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    const methods = useForm<SponsorModel>();

    useEffect(() => {
        if (id) {
            setLoading(true);
            getSponsor(id).then((sponsor) => {
                setSponsor(sponsor);
                setLoading(false);
            });
        }
    }, []);

    return Sponsor ? (
        <FormProvider {...methods}>
            <FormBuilder<SponsorModel>
                data={Sponsor}
                formKey={FormBuilderKeys.Sponsor}
                loading={loading}
                cancel={() => navigate('/sponsors')}
            />
        </FormProvider>
    ) : (
        <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />
    );
};

export default Sponsor;
