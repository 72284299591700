import { CellRenderType } from 'enums';
import { ProgramTag } from 'types';

export const initialColumns: ProgramTag = {
    id: '',
    creationDate: new Date(),
    name: '',
};

export const cellRenderMapper: Record<keyof ProgramTag, CellRenderType> = {
    id: CellRenderType.TEXT,
    creationDate: CellRenderType.DATETIME,
    name: CellRenderType.TEXT,
};
