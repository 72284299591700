import React, { FC, useEffect } from 'react';
import { useConferences, useSpeakersContents } from 'contexts';
import { cellRenderMapper, initialColumns } from './mappers';
import { SpeakersContent, GetSpeakersContents, Conference } from 'types';
import { renderCell, getTableColumns } from 'utils';
import { PageBuilder } from 'containers';
import { FilterType, PageBuilderAdditionalFilters, PageBuilderKey } from 'enums';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

const SpeakersContents: FC = () => {
    const { speakersContents, getSpeakersContents, getExcel, deleteSpeakersContents } = useSpeakersContents();
    const { i18n } = useTranslation('common/shared');
    const navigate = useNavigate();

    const { getConferences, conferences, getCurrentConference, currentConference } = useConferences();

    const mapConferences = (conferences: Conference[]) =>
        conferences.map((item) => ({
            value: item.id,
            label: item.name,
        }));

    useEffect(() => {
        getConferences({ page: 0, size: 300 });
    }, []);

    return (
        <PageBuilder<Omit<SpeakersContent, 'tagIds' | 'conferencesId' | 'description' | 'number' | 'image' | 'tags'>>
            pageKey={PageBuilderKey.SpeakersContents}
            data={speakersContents}
            getItems={(params) => getSpeakersContents(params as GetSpeakersContents)}
            deleteItems={deleteSpeakersContents}
            columns={getTableColumns<
                Omit<SpeakersContent, 'tagIds' | 'conferencesId' | 'description' | 'number' | 'image' | 'tags'>
            >(initialColumns, cellRenderMapper, renderCell, i18n.getResourceBundle(i18n.language, 'common/shared'))}
            addItem={() => navigate('/speakers-content')}
            updateItem={(speakersContent) => navigate(`/speakers-content/${speakersContent.id}`)}
            hideBaseFilters
            additionalFilters={[
                { type: PageBuilderAdditionalFilters.Dropdown, key: FilterType.IsVisible },
                {
                    type: PageBuilderAdditionalFilters.DropdownWithSearch,
                    key: FilterType.Conference,
                    items: mapConferences(conferences.items ?? []),
                    withEmpty: true,
                    defaultValueFetchAsync: () => getCurrentConference().then((d) => d?.id),
                    defaultValue: currentConference?.id,
                },
            ]}
        />
    );
};

export default SpeakersContents;
