import { IApiService, IAgreementApi } from 'interfaces';
import { Agreement, GetAgreements, HttpResponse, UpdateMarketing } from 'types';
import BaseApi from './base-api.service';
import { ApiBaseKeys } from 'enums';

class AgreementApi extends BaseApi<GetAgreements, Agreement> implements IAgreementApi {
    constructor(apiService: IApiService) {
        super(apiService, ApiBaseKeys.Agreements);
    }
    public updateAgreement = (request: UpdateMarketing): Promise<HttpResponse<unknown>> => {
        return this.apiService.request<unknown>({
            method: 'POST',
            url: '/Agreement/UpdateMarketing',
            data: request,
        });
    };
}

export default AgreementApi;
