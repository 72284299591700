import React, { createContext, FC, Key, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { getApiService, getSpeakersContentsApiService, getStorageService } from 'services';
import { GetSpeakersContents, HttpResponse, SpeakersContent } from 'types';
import { PaginationOutput, downloadFileFromBase64, generateFormData } from 'kl-b2c-ui-kit';
import { dropdownToBoolean } from './mappers/dropdown-to-boolean';

interface SpeakersContentsContext {
    speakersContent: Omit<SpeakersContent, 'id' | 'creationDate'> | null;
    speakersContents: PaginationOutput<SpeakersContent>;
    getSpeakersContents: (params: GetSpeakersContents) => Promise<void>;
    getSpeakersContent: (id: string) => void;
    getExcel: (params: Omit<GetSpeakersContents, 'page' | 'size'>) => Promise<void>;
    deleteSpeakersContents: (ids: Key[]) => Promise<HttpResponse<string>>;
    addSpeakersContent: (data: Omit<SpeakersContent, 'id' | 'creationDate'>) => void;
    updateSpeakersContent: (data: Omit<SpeakersContent, 'creationDate'>) => void;
}

const SpeakersContentsContext = createContext<SpeakersContentsContext>({} as SpeakersContentsContext);

const SpeakersContentsProvider: FC<PropsWithChildren> = ({ children }) => {
    const storageService = getStorageService();
    const [speakersContent, setSpeakersContent] = useState<Omit<SpeakersContent, 'id' | 'creationDate'> | null>(null);
    const [speakersContents, setSpeakersContents] = useState<PaginationOutput<SpeakersContent>>({
        count: 0,
        items: null,
    });
    const apiService = getApiService(storageService);
    const speakersContentsApiService = getSpeakersContentsApiService(apiService);

    const getSpeakersContents = async (params: GetSpeakersContents) => {
        const speakersContents = await speakersContentsApiService.getItems({
            ...params,
            //@ts-ignore
            isVisible: dropdownToBoolean(params.isVisible),
        });
        setSpeakersContents(speakersContents.data);
    };

    const getSpeakersContent = async (id: string) => {
        const speakersContent = await speakersContentsApiService.getItem(id);
        return setSpeakersContent(speakersContent.data);
    };

    const addSpeakersContent = async (data: Omit<SpeakersContent, 'id' | 'creationDate'>) => {
        const speakersContent = await speakersContentsApiService.add(generateFormData(data));
        setSpeakersContent(speakersContent.data);
    };

    const updateSpeakersContent = async (data: Omit<SpeakersContent, 'creationDate'>) => {
        const speakersContent = await speakersContentsApiService.update(generateFormData(data));
        setSpeakersContent(speakersContent.data);
    };

    const deleteSpeakersContents = async (ids: Key[]) => {
        return await speakersContentsApiService.deleteMany(ids);
    };

    const getExcel = async (params: Omit<GetSpeakersContents, 'page' | 'size'>) => {
        const base64 = await speakersContentsApiService.getExcel(params);
        downloadFileFromBase64(base64.data, 'xlsx', 'speakersContents');
    };

    const memoValue = useMemo(
        () => ({
            getSpeakersContents,
            speakersContents,
            getExcel,
            deleteSpeakersContents,
            getSpeakersContent,
            speakersContent,
            addSpeakersContent,
            updateSpeakersContent,
        }),
        [getSpeakersContents, speakersContents, speakersContent, addSpeakersContent, updateSpeakersContent]
    );

    return <SpeakersContentsContext.Provider value={memoValue}>{children}</SpeakersContentsContext.Provider>;
};

export const useSpeakersContents = () => useContext(SpeakersContentsContext);

export default SpeakersContentsProvider;
