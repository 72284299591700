import React, { createContext, FC, Key, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { getApiService, getProgramTagsApiService, getStorageService } from 'services';
import { GetProgramTags, HttpResponse, ProgramTag } from 'types';
import { PaginationOutput, downloadFileFromBase64 } from 'kl-b2c-ui-kit';

interface ProgramTagsContext {
    programTag: Omit<ProgramTag, 'id' | 'creationDate'> | null;
    programTags: PaginationOutput<ProgramTag>;
    getProgramTags: (params: GetProgramTags) => Promise<void>;
    getProgramTag: (id: string) => void;
    getExcel: (params: Omit<GetProgramTags, 'page' | 'size'>) => Promise<void>;
    deleteProgramTags: (ids: Key[]) => Promise<HttpResponse<string>>;
    addProgramTag: (data: Omit<ProgramTag, 'id' | 'creationDate'>) => void;
    updateProgramTag: (data: Omit<ProgramTag, 'creationDate'>) => void;
}

const ProgramTagsContext = createContext<ProgramTagsContext>({} as ProgramTagsContext);

const ProgramTagsProvider: FC<PropsWithChildren> = ({ children }) => {
    const storageService = getStorageService();
    const [programTag, setProgramTag] = useState<Omit<ProgramTag, 'id' | 'creationDate'> | null>(null);
    const [programTags, setProgramTags] = useState<PaginationOutput<ProgramTag>>({
        count: 0,
        items: null,
    });
    const apiService = getApiService(storageService);
    const ProgramTagsApiService = getProgramTagsApiService(apiService);

    const getProgramTags = async (params: GetProgramTags) => {
        const ProgramTags = await ProgramTagsApiService.getItems(params);
        setProgramTags(ProgramTags.data);
    };

    const getProgramTag = async (id: string) => {
        const ProgramTag = await ProgramTagsApiService.getItem(id);
        return setProgramTag(ProgramTag.data);
    };

    const addProgramTag = async (data: Omit<ProgramTag, 'id' | 'creationDate'>) => {
        await ProgramTagsApiService.add(data);
    };

    const updateProgramTag = async (data: Omit<ProgramTag, 'creationDate'>) => {
        await ProgramTagsApiService.update(data);
    };

    const deleteProgramTags = async (ids: Key[]) => {
        return await ProgramTagsApiService.deleteMany(ids);
    };

    const getExcel = async (params: Omit<GetProgramTags, 'page' | 'size'>) => {
        const base64 = await ProgramTagsApiService.getExcel(params);
        downloadFileFromBase64(base64.data, 'xlsx', 'programTags');
    };

    const memoValue = useMemo(
        () => ({
            getProgramTags,
            programTags,
            getExcel,
            deleteProgramTags,
            getProgramTag,
            programTag,
            addProgramTag,
            updateProgramTag,
        }),
        [getProgramTags, programTags, programTag, addProgramTag, updateProgramTag]
    );

    return <ProgramTagsContext.Provider value={memoValue}>{children}</ProgramTagsContext.Provider>;
};

export const useProgramTags = () => useContext(ProgramTagsContext);

export default ProgramTagsProvider;
