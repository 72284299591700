import { ProgramEvent } from 'types';
import { ControlRendererType } from 'enums';
import { ControlRendererRepresentation } from 'types/form-builder/control-renderer-config';

type EditType = Omit<ProgramEvent, 'id' | 'creationDate'>;

export const programEventRendererTypes = (
    t: (key: string, options?: Record<string, number | string>) => string
): Record<keyof EditType, ControlRendererRepresentation> => ({
    name: {
        type: ControlRendererType.Wysiwyg,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 200,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 200,
                }),
            },
        },
    },
    description: {
        type: ControlRendererType.Wysiwyg,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 2000,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 2000,
                }),
            },
        },
    },
    startTime: {
        type: ControlRendererType.Time,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 5,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 5,
                }),
            },
        },
    },
    endTime: {
        type: ControlRendererType.Time,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 5,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 5,
                }),
            },
        },
    },
    speakers: {
        type: ControlRendererType.Hidden,
    },
    speakerIds: {
        type: ControlRendererType.Hidden,
    },
    tags: {
        type: ControlRendererType.Hidden,
    },
    tagIds: {
        type: ControlRendererType.Hidden,
    },
    number: {
        type: ControlRendererType.Number,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            validate: (value: number) => {
                if (!Number.isInteger(Number(value)) || Number(value) < 1) {
                    return t('only-natural', { ns: 'common/errors' });
                }
                return true;
            },
        },
    },
    visible: {
        type: ControlRendererType.CheckBox,
    },
    conferenceId: {
        type: ControlRendererType.Hidden,
    },
    conference: {
        type: ControlRendererType.Hidden,
    },
});
