import { MenuItem } from 'types';

export const MENU_ITEMS: MenuItem[] = [
    {
        iconName: 'Active',
        label: 'Agreements',
        key: '/agreements',
    },
    {
        iconName: 'Star',
        label: 'Conferences',
        key: '/conferences',
    },
    {
        iconName: 'AddGroupUsers',
        label: 'Participants',
        key: '/participants',
    },
    {
        iconName: 'Microphone',
        label: 'Speakers',
        key: '/speakers',
    },
    {
        iconName: 'AddGroupUsers',
        label: 'Sponsors',
        key: '/sponsors',
    },
    {
        iconName: 'FullScan',
        label: 'Marketing requests',
        key: '/marketing-requests',
    },
    {
        iconName: 'Analytics',
        label: 'Program',
        key: '/program/tags',
    },
    {
        iconName: 'Settings',
        label: 'Content',
        key: '/content/general',
    },
];
