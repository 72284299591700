import {
    GlobalSiteConfig,
    ControlRendererConfig,
    Conference,
    Agreement,
    Speaker,
    Sponsor,
    Participant,
    Committee,
    SpeakersTag,
    SpeakersContent,
    SponsorsContent,
    ProgramTag,
    ProgramModerator,
    ProgramEvent,
    ProgramSession,
} from 'types';
import { ControlRendererType, FormBuilderKeys } from 'enums';
import {
    configRendererTypes,
    conferenceRendererTypes,
    agreementRendererTypes,
    speakerRendererTypes,
    committeeRendererTypes,
    speakersTagRendererTypes,
    speakersContentRendererTypes,
    sponsorsContentRendererTypes,
    programTagRendererTypes,
    programModeratorRendererTypes,
    programEventRendererTypes,
    programSessionRendererTypes,
} from './control-configs';
import { RegisterOptions } from 'react-hook-form';
import { omit } from 'kl-b2c-ui-kit';

// These are available types for pair TYPE: CONTROL_TYPE for example if you want to add form for Speakers
// you add | Speaker here.
type AvailableTypes = GlobalSiteConfig | Conference | Agreement;

// This is mapper that get controls types by ExcludedControlType, for example Config and set it to object with solid
// typings, in Config it is Record<keyof AppConfig, ControlRendererType>, so in imported configRendererTypes you cannot
// add properties that are not in AppConfig
const controlTypes = (
    key: FormBuilderKeys,
    t: (key: string, options?: Record<string, number | string>) => string
): Record<keyof AvailableTypes, { type: ControlRendererType; rules?: RegisterOptions; readonly?: boolean }> => {
    const types = {
        Conference: conferenceRendererTypes(t),
        Config: configRendererTypes(t),
        Agreement: agreementRendererTypes(t),
        Speaker: speakerRendererTypes(t),
        Sponsor: speakerRendererTypes(t),
        Participant: speakerRendererTypes(t),
        Committee: committeeRendererTypes(t),
        SpeakersTag: speakersTagRendererTypes(t),
        SpeakersContent: speakersContentRendererTypes(t),
        SponsorsContent: sponsorsContentRendererTypes(t),
        ProgramTag: programTagRendererTypes(t),
        ProgramModerator: programModeratorRendererTypes(t),
        ProgramEvent: programEventRendererTypes(t),
        ProgramSession: programSessionRendererTypes(t),
    };

    return types[key];
};

export const getControlRenderer = <T>(
    formKey: FormBuilderKeys,
    data: T,
    t: (key: string, options?: Record<string, number | string>) => string
) => {
    switch (formKey) {
        case FormBuilderKeys.Config: {
            return getConfigPageForm<GlobalSiteConfig>(data as GlobalSiteConfig, formKey, t);
        }
        case FormBuilderKeys.Conference: {
            return getConfigPageForm<Conference>(omit<T>(['id', 'creationDate'], data) as Conference, formKey, t);
        }
        case FormBuilderKeys.Speaker: {
            const conference = (data as Speaker).conference?.name;
            return getConfigPageForm<Speaker>(
                omit<T>(['id'], {
                    ...data,
                    conference,
                    agreementId: `/agreements/${(data as Speaker).agreementId}`,
                }) as Speaker,
                formKey,
                t
            );
        }
        case FormBuilderKeys.Committee: {
            return getConfigPageForm<Committee>(
                omit<T>(['id', 'conference', 'creationDate'], {
                    ...data,
                }) as Committee,
                formKey,
                t
            );
        }
        case FormBuilderKeys.SpeakersTag: {
            return getConfigPageForm<SpeakersTag>(
                omit<T>(['id', 'creationDate'], {
                    ...data,
                }) as SpeakersTag,
                formKey,
                t
            );
        }
        case FormBuilderKeys.ProgramTag: {
            return getConfigPageForm<ProgramTag>(
                omit<T>(['id', 'creationDate'], {
                    ...data,
                }) as ProgramTag,
                formKey,
                t
            );
        }
        case FormBuilderKeys.ProgramModerator: {
            return getConfigPageForm<ProgramModerator>(
                omit<T>(['id', 'creationDate'], {
                    ...data,
                }) as ProgramModerator,
                formKey,
                t
            );
        }
        case FormBuilderKeys.ProgramEvent: {
            return getConfigPageForm<ProgramEvent>(
                omit<T>(['id', 'creationDate'], {
                    ...data,
                }) as ProgramEvent,
                formKey,
                t
            );
        }
        case FormBuilderKeys.ProgramSession: {
            return getConfigPageForm<ProgramSession>(
                omit<T>(['id', 'creationDate'], {
                    ...data,
                }) as ProgramSession,
                formKey,
                t
            );
        }
        case FormBuilderKeys.SpeakersContent: {
            return getConfigPageForm<SpeakersContent>(
                omit<T>(['id', 'creationDate'], {
                    ...data,
                }) as SpeakersContent,
                formKey,
                t
            );
        }
        case FormBuilderKeys.SponsorsContent: {
            return getConfigPageForm<SponsorsContent>(
                omit<T>(['id', 'creationDate'], {
                    ...data,
                }) as SponsorsContent,
                formKey,
                t
            );
        }
        case FormBuilderKeys.Sponsor: {
            const conference = (data as Sponsor).conference?.name;
            return getConfigPageForm<Sponsor>(
                omit<T>(['id'], {
                    ...data,
                    conference,
                    agreementId: `/agreements/${(data as Sponsor).agreementId}`,
                }) as Sponsor,
                formKey,
                t
            );
        }
        case FormBuilderKeys.Participant: {
            const conference = (data as Participant).conference?.name;
            return getConfigPageForm<Participant>(
                omit<T>(['id'], {
                    ...data,
                    conference,
                    agreementId: `/agreements/${(data as Participant).agreementId}`,
                }) as Participant,
                formKey,
                t
            );
        }
        case FormBuilderKeys.Agreement: {
            return getConfigPageForm<Agreement>(
                omit<T>(
                    [
                        'id',
                        'creationDate',
                        'id',
                        'chapter',
                        'email',
                        'privacyPolicyIsSelected',
                        'marketingCheckBoxText',
                        'touCheckBoxText',
                        'touText',
                        'privacyPolicyCheckBoxText',
                        'privacyPolicyText',
                    ],
                    data
                ) as Agreement,
                formKey,
                t
            );
        }
        default: {
            throw new Error(`There is no control renderer for ${formKey}, consider to add one`);
        }
    }
};

const getConfigPageForm = <T>(
    data: T,
    formKey: FormBuilderKeys,
    t: (key: string, options?: Record<string, number | string>) => string
): Record<keyof T, ControlRendererConfig> => {
    let result = {} as Record<keyof T, ControlRendererConfig>;
    const controlType = controlTypes(formKey as FormBuilderKeys, t);

    Object.keys(data as keyof AvailableTypes).forEach((key: string) => {
        console.log(key, controlType);
        const { type, rules, readonly, image } = controlType[key as keyof AvailableTypes];

        result = {
            ...result,
            [key]: {
                controlValue: data[key as keyof T],
                config: {
                    type,
                    rules,
                    readonly,
                    image,
                },
                key,
            },
        };
    });

    return result;
};
