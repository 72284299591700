import React, { Key, ReactNode, useEffect, useState } from 'react';
import { Button, Heading, Loader, Pagination, Table, Space } from '@kl/components-v6';
import { useTranslation } from 'react-i18next';
import { HttpResponse, PageBuilderFilterType } from 'types';
import { PaginationOutput } from 'kl-b2c-ui-kit';
import { Filters } from './components';
import { ModalType, PageBuilderAdditionalFilters, PageBuilderKey, FilterType } from 'enums';
import { useModal, useToaster } from 'contexts';
import { useParams, useLocation } from 'react-router-dom';
import { getFilterMapper } from './mappers';

interface PageBuilderProps<TData extends { id: string }> {
    data: PaginationOutput<TData>;
    getItems: <TFilterParams>(params: TFilterParams) => Promise<void>;
    getExcel?: <TFilterParams>(params: Omit<TFilterParams, 'page' | 'size'>) => Promise<void>;
    deleteItems: (ids: Key[]) => Promise<HttpResponse<string>>;
    pageKey: PageBuilderKey;
    columns: { key: string; dataIndex: string; title: string }[];
    additionalFilters?: {
        withEmpty?: boolean;
        type: PageBuilderAdditionalFilters;
        key: FilterType;
        defaultValue?: string;
        defaultValueFetchAsync?: () => Promise<string | undefined>;
        items?: {
            value: string | null;
            label: string;
        }[];
    }[];
    addItem?: () => void;
    updateItem?: (record: TData) => void;
    isUpdate?: (record: TData) => boolean;
    getItem?: (id: string) => Promise<void>;
    showItem?: (record: TData) => void;
    hideBaseFilters?: boolean;
}

const PageBuilder = <TData extends { id: string }>(props: PageBuilderProps<TData>) => {
    const {
        data,
        getItems,
        deleteItems,
        getExcel,
        pageKey,
        additionalFilters,
        columns,
        addItem,
        updateItem,
        getItem,
        isUpdate: isUpdateVisible,
        showItem,
        hideBaseFilters,
    } = props;
    const [tableLoading, setTableLoading] = useState<boolean>(false);
    const [init, setInit] = useState(true);
    const [filters, setFilters] = useState<PageBuilderFilterType>(getFilterMapper(pageKey));
    const [deleteCandidates, setDeleteCandidates] = useState<Key[]>([]);
    const { setModal } = useModal();
    const { id } = useParams();
    const location = useLocation();
    const { setToaster } = useToaster();

    const { t } = useTranslation(['pages/page-builder', 'common/shared']);

    const isAsyncAdditionalFilter = () =>
        additionalFilters?.findIndex((filter) => filter?.defaultValueFetchAsync) ?? -1 >= 0;

    const setFilter = (newFilter: Record<string, string | number | undefined | null>) =>
        setFilters({
            ...filters,
            ...newFilter,
        });

    const getAdditionalButtons = (
        length: number
    ): { title: string; dataIndex: string; key: string; width: string }[] => {
        if (length < 1) {
            return [];
        }

        let buttons = [
            {
                title: '',
                dataIndex: 'delete',
                key: 'delete',
                width: '5%',
                render: (_: unknown, record: TData): ReactNode => (
                    <Button
                        mode="dangerFilled"
                        onClick={async () => {
                            const modalContent = <div>{t('deleteOne', { type: t(pageKey).toLowerCase() })}</div>;

                            setModal(ModalType.Confirm, modalContent, async () => {
                                try {
                                    setTableLoading(true);

                                    const { id } = record;
                                    const hasDeleteCandidate = deleteCandidates.some((key) => key === id);
                                    const response = await deleteItems(
                                        hasDeleteCandidate ? deleteCandidates : [...deleteCandidates, id]
                                    );

                                    if (response.status !== 200) throw new Error(response.data);

                                    await getItems({ ...filters });
                                    setDeleteCandidates([]);

                                    setToaster({
                                        type: 'success',
                                        message: t('successDelete'),
                                    });
                                } catch (e: unknown) {
                                    if (e instanceof Error) {
                                        setToaster({
                                            type: 'error',
                                            message: e?.message,
                                        });
                                    } else {
                                        setToaster({
                                            type: 'error',
                                            message: t('somethingWrong', { ns: 'common/shared' }),
                                        });
                                    }
                                } finally {
                                    setTableLoading(false);
                                }
                            });
                        }}
                    >
                        {t('delete', { ns: 'common/shared' })}
                    </Button>
                ),
            },
        ];

        if (updateItem) {
            buttons = [
                {
                    title: '',
                    dataIndex: 'update',
                    key: 'update',
                    width: '5%',
                    render: (_: unknown, record: TData) => {
                        const button = (
                            <Button mode={'primaryBlue'} onClick={() => updateItem(record)}>
                                {t('update', { ns: 'common/shared' })}
                            </Button>
                        );
                        if (isUpdateVisible && isUpdateVisible(record)) {
                            return button;
                        } else if (isUpdateVisible && !isUpdateVisible(record)) {
                            return '';
                        }
                        return button;
                    },
                },
                ...buttons,
            ];
        }

        if (showItem) {
            buttons = [
                {
                    title: '',
                    dataIndex: 'show',
                    key: 'show',
                    width: '5%',
                    render: (_: unknown, record: TData) => {
                        const button = (
                            <Button mode={'secondary'} onClick={() => showItem(record)}>
                                {t('show', { ns: 'common/shared' })}
                            </Button>
                        );
                        return button;
                    },
                },
                ...buttons,
            ];
        }

        return buttons;
    };

    useEffect(() => {
        if (id) {
            if (!getItem) {
                throw new Error('Provide function for single item request!');
            }

            setTableLoading(true);
            getItem(id)
                .catch((e) => {
                    setToaster({
                        type: 'error',
                        message: e.message,
                    });
                })
                .finally(() => setTableLoading(false));
        } else {
            setTableLoading(true);
            if (additionalFilters && isAsyncAdditionalFilter() && init) {
                const asyncFunctions = additionalFilters
                    .filter((filter) => filter.defaultValueFetchAsync)
                    .map((res) => ({
                        key: res.key,
                        func: res.defaultValueFetchAsync,
                    }));
                Promise.all(
                    asyncFunctions.map((f) => {
                        if (!f.func) return;
                        return f.func().then((val) => ({
                            key: f.key,
                            value: val,
                        }));
                    })
                )
                    .then((data) => {
                        const newFilters: Record<string, string | undefined> = {};
                        data.forEach((d) => {
                            if (d && d.key) {
                                newFilters[d.key] = d?.value;
                            }
                        });
                        setFilter({
                            ...filters,
                            ...newFilters,
                        });
                    })
                    .finally(() => {
                        setTableLoading(false);
                        setInit(false);
                    });
            } else {
                getItems({ ...filters, id })
                    .catch((e) => {
                        setToaster({
                            type: 'error',
                            message: e.message,
                        });
                    })
                    .finally(() => {
                        setTableLoading(false);
                        setInit(false);
                    });
            }
        }
    }, [filters, location]);

    useEffect(() => {
        setDeleteCandidates([]);
    }, [location]);

    return data?.items !== null ? (
        <>
            <Heading type={'H2'}>{t(pageKey)}</Heading>

            {addItem && (
                <Space direction="horizontal" size={15} style={{ marginTop: 30 }}>
                    <Button mode={'danger'} onClick={addItem}>
                        {t('add', { ns: 'common/shared' })}
                    </Button>
                </Space>
            )}

            {!id && (
                <Filters
                    setFilter={setFilter}
                    downloadExcel={getExcel}
                    disableExcelDownload={!data?.items.length}
                    additionalFilters={additionalFilters}
                    filters={filters}
                    hideBaseFilters={hideBaseFilters}
                />
            )}

            <Table
                rowSelection={{
                    type: 'checkbox',
                    onChange: async (deleteCandidates: Key[]) => setDeleteCandidates(deleteCandidates),
                    selectedRowKeys: deleteCandidates,
                }}
                loading={tableLoading}
                style={{ marginBottom: 20 }}
                locale={{ emptyText: t('emptyTable', { title: pageKey.toLowerCase() }) }}
                toolbar={{ showFilter: true }}
                pagination={false}
                dataSource={
                    data?.items.map((item: TData) => ({
                        ...item,
                        key: item.id,
                    })) || []
                }
                columns={[...columns, ...getAdditionalButtons(data?.items.length)]}
            />

            {!id && (
                <Pagination
                    onChange={(page, size) => {
                        setFilters((prevState: PageBuilderFilterType) => ({ ...prevState, page: page - 1, size }));
                    }}
                    onShowSizeChange={(page, size) => {
                        setFilters((prevState: PageBuilderFilterType) => ({
                            ...prevState,
                            page: page === 0 ? 0 : page - 1,
                            size,
                        }));
                    }}
                    total={data?.count || 0}
                    current={filters.page + 1}
                    pageSize={filters.size}
                />
            )}
        </>
    ) : (
        <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />
    );
};

export default PageBuilder;
