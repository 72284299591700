import { IApiService, IConfigApi } from 'interfaces';
import { GlobalSiteConfig, HttpResponse } from 'types';

class ConfigApi implements IConfigApi {
    constructor(private readonly apiService: IApiService) {}

    public getConfig(): Promise<HttpResponse<GlobalSiteConfig>> {
        return this.apiService.request<GlobalSiteConfig>({
            method: 'GET',
            url: '/siteSettings/get',
        });
    }

    public updateConfig(data: Omit<GlobalSiteConfig, 'updateDate'>): Promise<HttpResponse<GlobalSiteConfig>> {
        return this.apiService.request<GlobalSiteConfig>({
            method: 'POST',
            url: '/siteSettings/update',
            data,
        });
    }
}

export default ConfigApi;
