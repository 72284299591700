import { useConferences, useSpeakersContents, useSpeakersTags, useToaster } from 'contexts';
import { FormBuilderKeys } from 'enums/form-builder-keys';
import React, { FC, useEffect, useState } from 'react';
import { Controller, FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Conference, SpeakersContent as SpeakersContentModel, SpeakersTag } from 'types';
import { Heading, Loader, Select } from '@kl/components-v6';
import { FormBuilder } from 'containers';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { ErrorMessage, FormRow } from 'containers/form-builder/styled';

const INITIAL_DATA: Omit<SpeakersContentModel, 'tagIds' | 'conferences'> = {
    id: '',
    creationDate: new Date(),
    firstName: '',
    lastName: '',
    position: '',
    nameOfReport: '',
    twitterNickName: '',
    description: '',
    visible: false,
    number: 1,
    image: null,
    tags: null,
};

const SpeakersContent: FC = () => {
    const { getSpeakersContent, speakersContent, updateSpeakersContent, addSpeakersContent } = useSpeakersContents();
    const { speakersTags, getSpeakersTags } = useSpeakersTags();
    const { conferences, getConferences } = useConferences();
    const { id } = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const { t } = useTranslation(['pages/speakers-contents', 'common/shared']);
    const { setToaster } = useToaster();

    const methods = useForm<SpeakersContentModel>();

    const {
        control,
        formState: { errors },
    } = methods;

    const onSubmit = async (data: FieldValues) => {
        setLoading(true);
        try {
            if (id) {
                await updateSpeakersContent({ ...data, id } as SpeakersContentModel);
            } else {
                await addSpeakersContent(data as SpeakersContentModel);
            }
            navigate('/content/speakers');
        } catch (e: unknown) {
            setToaster({
                message: (e as AxiosError).message ?? t('somethingWrong', { ns: 'common/shared' }),
                type: 'error',
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getSpeakersTags({ page: 0, size: 300 });
        getConferences({ page: 0, size: 300 });
        if (id) {
            getSpeakersContent(id);
        }
    }, []);

    if (id && (!speakersContent || !speakersTags || !conferences)) {
        return <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />;
    }

    return (
        <>
            <Heading type={'H2'}>{id ? t('update-speakersContent') : t('add-speakersContent')}</Heading>
            <FormProvider {...methods}>
                <FormBuilder<Omit<SpeakersContentModel, 'id' | 'creationDate' | 'conferences'>>
                    data={speakersContent || INITIAL_DATA}
                    formKey={FormBuilderKeys.SpeakersContent}
                    loading={loading}
                    isFormEmpty={!id}
                    submit={onSubmit}
                    cancel={() => navigate('/content/speakers')}
                >
                    <FormRow>
                        <span>{t('tags', { ns: 'common/shared' })}*</span>
                        <Controller
                            name={'tagIds'}
                            rules={{ required: true }}
                            control={control}
                            defaultValue={speakersContent?.tags?.map((tag) => tag.id)}
                            render={({ field: { onChange } }) => (
                                <Select
                                    mode={'multiple'}
                                    showSearch
                                    onChange={onChange}
                                    defaultValue={speakersContent?.tags?.map((tag) => tag.id)}
                                    options={speakersTags.items?.map((option: SpeakersTag) => ({
                                        label: option.name,
                                        value: option.id,
                                    }))}
                                />
                            )}
                        />
                        {errors?.tagIds && <ErrorMessage>{t('required-field', { ns: 'common/errors' })}</ErrorMessage>}
                    </FormRow>
                    <FormRow>
                        <span>{t('conferences', { ns: 'common/shared' })}*</span>
                        <Controller
                            name={'conferencesId'}
                            rules={{ required: true }}
                            control={control}
                            defaultValue={speakersContent?.conferences?.map((conference: Conference) => conference.id)}
                            render={({ field: { onChange, value } }) => (
                                <Select
                                    showSearch
                                    mode={'multiple'}
                                    onChange={onChange}
                                    defaultValue={speakersContent?.conferences?.map(
                                        (conference: Conference) => conference.id
                                    )}
                                    options={conferences.items?.map((option: Conference) => ({
                                        label: option.name,
                                        value: option.id,
                                    }))}
                                />
                            )}
                        />
                        {errors?.conferencesId && (
                            <ErrorMessage>{t('required-field', { ns: 'common/errors' })}</ErrorMessage>
                        )}
                    </FormRow>
                </FormBuilder>
            </FormProvider>
        </>
    );
};

export default SpeakersContent;
