import { Tabs } from '@kl/components-v6';
import React from 'react';
import { useNavigate, Outlet } from 'react-router-dom';

const elements = [
    {
        tabTitle: 'Tags',
        path: '/program/tags',
    },
    {
        tabTitle: 'Moderators',
        path: '/program/moderators',
    },
    {
        tabTitle: 'Sessions',
        path: '/program/sessions',
    },
    {
        tabTitle: 'Events',
        path: '/program/events',
    },
];

const ProgramBase = () => {
    const navigate = useNavigate();

    return (
        <>
            <Tabs activeKey={window.location.pathname} onChange={(path) => navigate(path)}>
                {elements.map((element) => (
                    <Tabs.TabPane tab={element.tabTitle} key={element.path} />
                ))}
            </Tabs>

            <Outlet />
        </>
    );
};

export default ProgramBase;
