import React, { createContext, FC, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { getApiService, getConfigApiService, getStorageService } from 'services';
import { getConferencesApiService } from 'services/api.factory';
import { GlobalSiteConfig, GlobalSiteConfigUpdate, Conference } from 'types';

interface ConfigContext {
    config: GlobalSiteConfig | null;
    conference: Conference | null;
    getConfig: () => Promise<void>;
    updateConfig: (params: GlobalSiteConfigUpdate) => Promise<void>;
}

const ConfigContext = createContext<ConfigContext>({} as ConfigContext);

const ConfigProvider: FC<PropsWithChildren> = ({ children }) => {
    const storageService = getStorageService();
    const [config, setConfig] = useState<GlobalSiteConfig | null>(null);
    const [conference, setConference] = useState<Conference | null>(null);
    const apiService = getApiService(storageService);
    const configApiService = getConfigApiService(apiService);
    const conferenceApiService = getConferencesApiService(apiService);

    useEffect(() => {
        getConfig();
    }, []);

    const getConfig = async () => {
        const config = await configApiService.getConfig();
        const currentConference = await conferenceApiService.getCurrent();
        setConference(currentConference.data);
        setConfig(config.data);
    };

    const updateConfig = async (params: GlobalSiteConfigUpdate) => {
        const config = await configApiService.updateConfig(params);
        if (config.status === 207) throw new Error(config.data as unknown as string);
        setConfig(config.data);
    };

    const memoValue = useMemo(
        () => ({
            getConfig,
            updateConfig,
            conference,
            config,
        }),
        [getConfig, updateConfig, config]
    );

    return <ConfigContext.Provider value={memoValue}>{children}</ConfigContext.Provider>;
};

export const useConfig = () => useContext(ConfigContext);

export default ConfigProvider;
