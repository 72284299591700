import { useParticipants } from 'contexts';
import { FormBuilderKeys } from 'enums/form-builder-keys';
import { t } from 'i18next';
import { omit } from 'kl-b2c-ui-kit';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Participant as ParticipantModel } from 'types';
import { FormBuilder } from '..';
import { Loader } from '@kl/components-v6';

const Participant: FC = () => {
    const { getParticipant } = useParticipants();
    const { id } = useParams();
    const [Participant, setParticipant] = useState<ParticipantModel | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    const methods = useForm<ParticipantModel>();

    useEffect(() => {
        if (id) {
            setLoading(true);
            getParticipant(id).then((participant) => {
                setParticipant(participant);
                setLoading(false);
            });
        }
    }, []);

    return Participant ? (
        <FormProvider {...methods}>
            <FormBuilder<ParticipantModel>
                data={Participant}
                formKey={FormBuilderKeys.Participant}
                loading={loading}
                cancel={() => navigate('/participants')}
            />
        </FormProvider>
    ) : (
        <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />
    );
};

export default Participant;
