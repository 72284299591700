import React, { createContext, FC, Key, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { getApiService, getAgreementsApiService, getStorageService } from 'services';
import { Agreement, HttpResponse, UpdateMarketing } from 'types';
import { PaginationOutput, downloadFileFromBase64 } from 'kl-b2c-ui-kit';
import { AgreementsChapter } from 'enums/agreements-chapter';
import { dropdownToBoolean } from './mappers/dropdown-to-boolean';
import { GetAgreementsFilters } from 'types/agreements/get-agreements-filters';

interface AgreementsContext {
    agreements: PaginationOutput<Agreement>;
    agreement?: Agreement;
    loading: boolean;
    getAgreements: (params: GetAgreementsFilters) => Promise<void>;
    getAgreement: (id: string) => Promise<void>;
    getExcel: (params: Omit<GetAgreementsFilters, 'page' | 'size'>) => Promise<void>;
    deleteAgreements: (ids: Key[]) => Promise<HttpResponse<string>>;
    updateAgreement: (request: UpdateMarketing) => Promise<HttpResponse<unknown>>;
}

const AgreementsContext = createContext<AgreementsContext>({} as AgreementsContext);

const AgreementsProvider: FC<PropsWithChildren> = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const storageService = getStorageService();
    const [agreement, setAgreement] = useState<Agreement>();
    const [agreements, setAgreements] = useState<PaginationOutput<Agreement>>({
        count: 0,
        items: null,
    });
    const apiService = getApiService(storageService);
    const agreementsApiService = getAgreementsApiService(apiService);

    const getAgreements = async (params: GetAgreementsFilters) => {
        setLoading(true);
        const agreements = await agreementsApiService.getItems({
            ...params,
            chapter: params.chapter !== AgreementsChapter.All ? params.chapter : null,
            isMarketing: dropdownToBoolean(params.isMarketing),
        });
        setAgreements(agreements.data);
        setLoading(false);
    };

    const getAgreement = async (id: string) => {
        setLoading(true);
        const agreement = await agreementsApiService.getItem(id);
        setAgreements({ count: 1, items: [agreement.data] });
        setAgreement(agreement.data);
        setLoading(false);
    };

    const deleteAgreements = async (ids: Key[]) => {
        setLoading(true);
        const result = await agreementsApiService.deleteMany(ids);
        setLoading(false);
        return result;
    };

    const getExcel = async (params: Omit<GetAgreementsFilters, 'page' | 'size'>) => {
        const base64 = await agreementsApiService.getExcel({
            ...params,
            chapter: params.chapter !== AgreementsChapter.All ? params.chapter : null,
            isMarketing: dropdownToBoolean(params.isMarketing),
        });
        downloadFileFromBase64(base64.data, 'xlsx', 'agreements');
    };
    const updateAgreement = (request: UpdateMarketing) => {
        setLoading(true);
        const result = agreementsApiService.updateAgreement(request);
        setLoading(false);
        return result;
    };

    const memoValue = useMemo(
        () => ({
            getAgreements,
            agreements,
            agreement,
            loading,
            getExcel,
            deleteAgreements,
            getAgreement,
            updateAgreement,
        }),
        [getAgreements, agreements, getAgreement, getExcel, deleteAgreements]
    );

    return <AgreementsContext.Provider value={memoValue}>{children}</AgreementsContext.Provider>;
};

export const useAgreements = () => useContext(AgreementsContext);

export default AgreementsProvider;
