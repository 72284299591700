export enum CellRenderType {
    BOOLEAN = 'BOOLEAN',
    DATETIME = 'DATETIME',
    DATE = 'DATE',
    DATE_FIX_TIMEZONE = 'DATE_FIX_TIMEZONE',
    TEXT = 'TEXT',
    MARKUP = 'MARKUP',
    CHAPTER = 'CHAPTER',
    FILE = 'FILE',
    DOMAIN_TYPE = 'DOMAIN_TYPE',
    IMAGE_PREVIEW = 'IMAGE_PREVIEW',
    AGREEMENT_ID = 'AGREEMENT_ID',
    GROUP = 'GROUP',
    CONFERENCE = 'CONFERENCE',
    SPEAKER_TAG = 'SPEAKER_TAG',
    CONFERENCES = 'CONFERENCES',
}
