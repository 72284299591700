import React, { FC } from 'react';
import { useProgramModerators } from 'contexts';
import { cellRenderMapper, initialColumns } from './columns';
import { ProgramModerator, GetProgramModerators } from 'types';
import { renderCell, getTableColumns } from 'utils';
import { PageBuilder } from 'containers';
import { PageBuilderKey } from 'enums';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

const ProgramModerators: FC = () => {
    const { programModerators, getProgramModerators, getExcel, deleteProgramModerators } = useProgramModerators();
    const { i18n } = useTranslation('common/shared');
    const navigate = useNavigate();

    return (
        <PageBuilder<ProgramModerator>
            pageKey={PageBuilderKey.ProgramModerators}
            data={programModerators}
            getItems={(params) => getProgramModerators(params as GetProgramModerators)}
            deleteItems={deleteProgramModerators}
            columns={getTableColumns<ProgramModerator>(
                initialColumns,
                cellRenderMapper,
                renderCell,
                i18n.getResourceBundle(i18n.language, 'common/shared')
            )}
            addItem={() => navigate('/program/moderator/')}
            updateItem={(programModerator: ProgramModerator) => navigate(`/program/moderator/${programModerator.id}`)}
            hideBaseFilters
        />
    );
};

export default ProgramModerators;
