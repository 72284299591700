import React, { FC, useEffect } from 'react';
import { useConferences, useProgramSessions } from 'contexts';
import { cellRenderMapper, initialColumns } from './columns';
import { ProgramSession, GetProgramSessions, Conference } from 'types';
import { renderCell, getTableColumns } from 'utils';
import { PageBuilder } from 'containers';
import { FilterType, PageBuilderAdditionalFilters, PageBuilderKey } from 'enums';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

const ProgramSessions: FC = () => {
    const { programSessions, getProgramSessions, getExcel, deleteProgramSessions } = useProgramSessions();
    const { i18n } = useTranslation('common/shared');
    const navigate = useNavigate();

    const { getConferences, conferences, getCurrentConference, currentConference } = useConferences();

    const mapConferences = (conferences: Conference[]) =>
        conferences.map((item) => ({
            value: item.id,
            label: item.name,
        }));

    useEffect(() => {
        getConferences({ page: 0, size: 300 });
    }, []);

    return (
        <PageBuilder<
            Omit<
                ProgramSession,
                'moderator' | 'moderatorId' | 'number' | 'programEvents' | 'programEventIds' | 'conferenceId'
            >
        >
            pageKey={PageBuilderKey.ProgramSessions}
            data={programSessions}
            getItems={(params) => getProgramSessions(params as GetProgramSessions)}
            deleteItems={deleteProgramSessions}
            columns={getTableColumns<
                Omit<
                    ProgramSession,
                    | 'moderator'
                    | 'moderatorId'
                    | 'number'
                    | 'programEvents'
                    | 'programEventIds'
                    | 'conferenceId'
                    | 'startTime'
                    | 'endTime'
                    | 'location'
                >
            >(initialColumns, cellRenderMapper, renderCell, i18n.getResourceBundle(i18n.language, 'common/shared'))}
            addItem={() => navigate('/program/session/')}
            updateItem={(programSession) => navigate(`/program/session/${programSession.id}`)}
            hideBaseFilters
            additionalFilters={[
                { type: PageBuilderAdditionalFilters.Dropdown, key: FilterType.IsVisible },
                {
                    type: PageBuilderAdditionalFilters.DropdownWithSearch,
                    key: FilterType.Conference,
                    items: mapConferences(conferences.items ?? []),
                    withEmpty: true,
                    defaultValueFetchAsync: () => getCurrentConference().then((d) => d?.id),
                    defaultValue: currentConference?.id,
                },
            ]}
        />
    );
};

export default ProgramSessions;
