import React, { FC, useEffect } from 'react';
import { useConferences, useProgramEvents } from 'contexts';
import { cellRenderMapper, initialColumns } from './columns';
import { ProgramEvent, GetProgramEvents, Conference } from 'types';
import { renderCell, getTableColumns } from 'utils';
import { PageBuilder } from 'containers';
import { FilterType, PageBuilderAdditionalFilters, PageBuilderKey } from 'enums';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

const ProgramEvents: FC = () => {
    const { programEvents, getProgramEvents, getExcel, deleteProgramEvents } = useProgramEvents();
    const { i18n } = useTranslation('common/shared');
    const navigate = useNavigate();

    const { getConferences, conferences, getCurrentConference, currentConference } = useConferences();

    const mapConferences = (conferences: Conference[]) =>
        conferences.map((item) => ({
            value: item.id,
            label: item.name,
        }));

    useEffect(() => {
        getConferences({ page: 0, size: 300 });
    }, []);

    return (
        <PageBuilder<
            Omit<ProgramEvent, 'speakers' | 'speakerIds' | 'tags' | 'tagIds' | 'number' | 'conferenceId' | 'location'>
        >
            pageKey={PageBuilderKey.ProgramEvents}
            data={programEvents}
            getItems={(params) => getProgramEvents(params as GetProgramEvents)}
            deleteItems={deleteProgramEvents}
            columns={getTableColumns<
                Omit<
                    ProgramEvent,
                    'speakers' | 'speakerIds' | 'tags' | 'tagIds' | 'number' | 'conferenceId' | 'location'
                >
            >(initialColumns, cellRenderMapper, renderCell, i18n.getResourceBundle(i18n.language, 'common/shared'))}
            addItem={() => navigate('/program/event/')}
            updateItem={(programEvent) => navigate(`/program/event/${programEvent.id}`)}
            hideBaseFilters
            additionalFilters={[
                { type: PageBuilderAdditionalFilters.Dropdown, key: FilterType.IsVisible },
                {
                    type: PageBuilderAdditionalFilters.DropdownWithSearch,
                    key: FilterType.Conference,
                    items: mapConferences(conferences.items ?? []),
                    withEmpty: true,
                    defaultValueFetchAsync: () => getCurrentConference().then((d) => d?.id),
                    defaultValue: currentConference?.id,
                },
            ]}
        />
    );
};

export default ProgramEvents;
