import React, { createContext, FC, Key, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { getApiService, getProgramSessionsApiService, getStorageService } from 'services';
import { GetProgramSessions, HttpResponse, ProgramSession } from 'types';
import { PaginationOutput, downloadFileFromBase64 } from 'kl-b2c-ui-kit';
import { dropdownToBoolean } from './mappers/dropdown-to-boolean';

interface ProgramSessionsContext {
    programSession: Omit<ProgramSession, 'id' | 'creationDate'> | null;
    programSessions: PaginationOutput<ProgramSession>;
    getProgramSessions: (params: GetProgramSessions) => Promise<void>;
    getProgramSession: (id: string) => void;
    getExcel: (params: Omit<GetProgramSessions, 'page' | 'size'>) => Promise<void>;
    deleteProgramSessions: (ids: Key[]) => Promise<HttpResponse<string>>;
    addProgramSession: (data: Omit<ProgramSession, 'id' | 'creationDate'>) => void;
    updateProgramSession: (data: Omit<ProgramSession, 'creationDate'>) => void;
}

const ProgramSessionsContext = createContext<ProgramSessionsContext>({} as ProgramSessionsContext);

const ProgramSessionsProvider: FC<PropsWithChildren> = ({ children }) => {
    const storageService = getStorageService();
    const [programSession, setProgramSession] = useState<Omit<ProgramSession, 'id' | 'creationDate'> | null>(null);
    const [programSessions, setProgramSessions] = useState<PaginationOutput<ProgramSession>>({
        count: 0,
        items: null,
    });
    const apiService = getApiService(storageService);
    const programSessionsApiService = getProgramSessionsApiService(apiService);

    const getProgramSessions = async (params: GetProgramSessions) => {
        const ProgramSessions = await programSessionsApiService.getItems({
            ...params,
            //@ts-ignore
            isVisible: dropdownToBoolean(params.isVisible),
        });
        setProgramSessions(ProgramSessions.data);
    };

    const getProgramSession = async (id: string) => {
        const ProgramSession = await programSessionsApiService.getItem(id);
        return setProgramSession(ProgramSession.data);
    };

    const addProgramSession = async (data: Omit<ProgramSession, 'id' | 'creationDate'>) => {
        await programSessionsApiService.add(data);
    };

    const updateProgramSession = async (data: Omit<ProgramSession, 'creationDate'>) => {
        await programSessionsApiService.update(data);
    };

    const deleteProgramSessions = async (ids: Key[]) => {
        return await programSessionsApiService.deleteMany(ids);
    };

    const getExcel = async (params: Omit<GetProgramSessions, 'page' | 'size'>) => {
        const base64 = await programSessionsApiService.getExcel(params);
        downloadFileFromBase64(base64.data, 'xlsx', 'programSessions');
    };

    const memoValue = useMemo(
        () => ({
            getProgramSessions,
            programSessions,
            getExcel,
            deleteProgramSessions,
            getProgramSession,
            programSession,
            addProgramSession,
            updateProgramSession,
        }),
        [getProgramSessions, programSessions, programSession, addProgramSession, updateProgramSession]
    );

    return <ProgramSessionsContext.Provider value={memoValue}>{children}</ProgramSessionsContext.Provider>;
};

export const useProgramSessions = () => useContext(ProgramSessionsContext);

export default ProgramSessionsProvider;
