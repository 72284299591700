export enum ApiBaseKeys {
    Agreements = 'agreement',
    Participants = 'participant',
    Sponsors = 'sponsor',
    Speakers = 'speaker',
    MarketingRequests = 'notificationOrder',
    Conferences = 'conference',
    Committees = 'committee',
    SpeakersTags = 'speakerContentTag',
    SpeakersContents = 'speakerContent',
    SponsorsContents = 'sponsorContent',
    ProgramTags = 'programTag',
    ProgramModerators = 'programModerator',
    ProgramEvents = 'programEvent',
    ProgramSessions = 'programSession',
}
