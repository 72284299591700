import React, { FC, useEffect, useState } from 'react';
import { useConferences, useProgramEvents, useProgramTags, useSpeakersContents, useToaster } from 'contexts';
import { FormBuilderKeys } from 'enums/form-builder-keys';
import { Controller, FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Conference, ProgramEvent as ProgramEventModel, ProgramTag, SpeakersContent } from 'types';
import { Heading, Loader, Select } from '@kl/components-v6';
import { FormBuilder } from 'containers';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { ErrorMessage } from 'containers/form-builder/styled';
import { FormRow } from 'containers/form-builder/components';

const INITIAL_DATA: Omit<ProgramEventModel, 'tagIds' | 'speakerIds' | 'conference'> = {
    id: '',
    creationDate: new Date(),
    name: '',
    description: '',
    startTime: '',
    endTime: '',
    speakers: [],
    tags: [],
    number: 1,
    visible: true,
};

const ProgramEvent: FC = () => {
    const { getProgramEvent, programEvent, updateProgramEvent, addProgramEvent } = useProgramEvents();
    const { programTags, getProgramTags } = useProgramTags();
    const { conferences, getConferences } = useConferences();
    const { speakersContents, getSpeakersContents } = useSpeakersContents();
    const { id } = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const { t } = useTranslation(['pages/program-events', 'common/shared']);
    const { setToaster } = useToaster();

    const methods = useForm<ProgramEventModel>();

    const {
        control,
        formState: { errors },
        watch,
    } = methods;

    const onSubmit = async (data: FieldValues) => {
        setLoading(true);
        try {
            if (id) {
                await updateProgramEvent({ ...data, id } as ProgramEventModel);
            } else {
                await addProgramEvent(data as ProgramEventModel);
            }
            navigate('/program/events');
        } catch (e: unknown) {
            setToaster({
                message: (e as AxiosError).message ?? t('somethingWrong', { ns: 'common/shared' }),
                type: 'error',
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getProgramTags({ page: 0, size: 300 });
        getConferences({ page: 0, size: 300 });
        getSpeakersContents({ page: 0, size: 300, isVisible: null, conferenceId: null });
        if (id) {
            getProgramEvent(id);
        }
    }, []);

    if (id && (!programEvent || !programTags || !conferences || !speakersContents)) {
        return <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />;
    }

    return (
        <>
            <Heading type={'H2'}>{id ? t('update-programEvent') : t('add-programEvent')}</Heading>
            <FormProvider {...methods}>
                <FormBuilder<
                    Omit<
                        ProgramEventModel,
                        'id' | 'creationDate' | 'conference' | 'tags' | 'speakers' | 'tagIds' | 'speakerIds'
                    >
                >
                    data={programEvent || INITIAL_DATA}
                    formKey={FormBuilderKeys.ProgramEvent}
                    loading={loading}
                    isFormEmpty={!id}
                    submit={onSubmit}
                    cancel={() => navigate('/program/events')}
                >
                    <FormRow key={'speakers'} label={t('speakers', { ns: 'common/shared' })} required>
                        <Controller
                            name={'speakerIds'}
                            rules={{ required: true }}
                            control={control}
                            defaultValue={programEvent?.speakers?.map((speaker) => speaker.id)}
                            render={({ field: { onChange } }) => (
                                <Select
                                    mode={'multiple'}
                                    showSearch
                                    onChange={onChange}
                                    defaultValue={programEvent?.speakers?.map((speaker) => speaker.id)}
                                    options={speakersContents.items?.map((option: SpeakersContent) => ({
                                        label: option.firstName + ' ' + option.lastName,
                                        value: option.id,
                                    }))}
                                />
                            )}
                        />
                        {errors?.speakerIds && (
                            <ErrorMessage>{t('required-field', { ns: 'common/errors' })}</ErrorMessage>
                        )}
                    </FormRow>
                    <FormRow key={'tags'} label={t('tags', { ns: 'common/shared' })} required>
                        <Controller
                            name={'tagIds'}
                            rules={{ required: true }}
                            control={control}
                            defaultValue={programEvent?.tags?.map((tag) => tag.id)}
                            render={({ field: { onChange } }) => (
                                <Select
                                    mode={'multiple'}
                                    showSearch
                                    onChange={onChange}
                                    defaultValue={programEvent?.tags?.map((tag) => tag.id)}
                                    options={programTags.items?.map((option: ProgramTag) => ({
                                        label: option.name,
                                        value: option.id,
                                    }))}
                                />
                            )}
                        />
                        {errors?.tagIds && <ErrorMessage>{t('required-field', { ns: 'common/errors' })}</ErrorMessage>}
                    </FormRow>
                    <FormRow key={'conference'} label={t('conference', { ns: 'common/shared' })} required>
                        <Controller
                            name={'conferenceId'}
                            rules={{ required: true }}
                            control={control}
                            defaultValue={programEvent?.conference?.id}
                            render={({ field: { onChange, value } }) => (
                                <Select
                                    showSearch
                                    onChange={onChange}
                                    defaultValue={programEvent?.conference?.id}
                                    options={conferences.items?.map((option: Conference) => ({
                                        label: option.name,
                                        value: option.id,
                                    }))}
                                />
                            )}
                        />
                        {errors?.conferenceId && (
                            <ErrorMessage>{t('required-field', { ns: 'common/errors' })}</ErrorMessage>
                        )}
                    </FormRow>
                </FormBuilder>
            </FormProvider>
        </>
    );
};

export default ProgramEvent;
