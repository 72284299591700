import React, { FC, Key } from 'react';
import { useAgreements } from 'contexts';
import { cellRenderMapper, initialColumns } from './mappers';
import { Agreement, GetAgreementsFilters } from 'types/agreements';
import { renderCell, getTableColumns } from 'utils';
import { PageBuilder } from 'containers';
import { FilterType, PageBuilderAdditionalFilters, PageBuilderKey } from 'enums';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { HttpResponse } from 'types';

const Agreements: FC = () => {
    const { agreements, getAgreements, getAgreement, getExcel, deleteAgreements } = useAgreements();
    const { i18n } = useTranslation('pages/agreements');
    const { id } = useParams();
    const navigate = useNavigate();

    const onDelete = async (ids: Key[]): Promise<HttpResponse<string>> => {
        if (id) {
            await deleteAgreements(ids);
            navigate('/agreements');
        }

        return deleteAgreements(ids);
    };

    return (
        <PageBuilder<Agreement>
            pageKey={PageBuilderKey.Agreements}
            data={agreements}
            getItems={(params) => getAgreements(params as GetAgreementsFilters)}
            getItem={(id: string) => getAgreement(id)}
            getExcel={(params) => getExcel(params as Omit<GetAgreementsFilters, 'page' | 'size'>)}
            deleteItems={onDelete}
            columns={getTableColumns<Omit<Agreement, 'privacyPolicyText' | 'touText' | 'touCheckBoxText' | 'chapter'>>(
                initialColumns,
                cellRenderMapper,
                renderCell,
                i18n.getResourceBundle(i18n.language, 'pages/agreements')
            )}
            additionalFilters={[
                { type: PageBuilderAdditionalFilters.Dropdown, key: FilterType.Chapter },
                { type: PageBuilderAdditionalFilters.DebounceTextBox, key: FilterType.Email },
                { type: PageBuilderAdditionalFilters.Dropdown, key: FilterType.IsMarketing },
            ]}
            updateItem={(agr: Agreement) => navigate(`/agreement/${agr.id}`)}
            isUpdate={(agr: Agreement) => agr.marketingIsSelected}
        />
    );
};

export default Agreements;
