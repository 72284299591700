import React, { FC, useEffect } from 'react';
import { useConferences, useSponsorsContents } from 'contexts';
import { cellRenderMapper, initialColumns } from './mappers';
import { SponsorsContent, GetSponsorsContents, Conference } from 'types';
import { renderCell, getTableColumns } from 'utils';
import { PageBuilder } from 'containers';
import { FilterType, PageBuilderAdditionalFilters, PageBuilderKey } from 'enums';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

const SponsorsContents: FC = () => {
    const { sponsorsContents, getSponsorsContents, deleteSponsorsContents } = useSponsorsContents();
    const { i18n } = useTranslation('common/shared');
    const navigate = useNavigate();

    return (
        <PageBuilder<SponsorsContent>
            pageKey={PageBuilderKey.SponsorsContents}
            data={sponsorsContents}
            getItems={(params) => getSponsorsContents(params as GetSponsorsContents)}
            deleteItems={deleteSponsorsContents}
            columns={getTableColumns<SponsorsContent>(
                initialColumns,
                cellRenderMapper,
                renderCell,
                i18n.getResourceBundle(i18n.language, 'common/shared')
            )}
            addItem={() => navigate('/sponsors-content')}
            updateItem={(sponsorsContent: SponsorsContent) => navigate(`/sponsors-content/${sponsorsContent.id}`)}
            hideBaseFilters
            additionalFilters={[{ type: PageBuilderAdditionalFilters.Dropdown, key: FilterType.IsVisible }]}
        />
    );
};

export default SponsorsContents;
