import { CellRenderType } from 'enums';
import { SpeakersContent } from 'types';

export const initialColumns: Omit<
    SpeakersContent,
    'tagIds' | 'conferencesId' | 'description' | 'number' | 'image' | 'tags'
> = {
    id: '',
    creationDate: new Date(),
    firstName: '',
    lastName: '',
    position: '',
    nameOfReport: '',
    twitterNickName: '',
    visible: false,
    conferences: [],
};

export const cellRenderMapper: Record<
    keyof Omit<SpeakersContent, 'tagIds' | 'conferencesId' | 'description' | 'number' | 'image' | 'tags'>,
    CellRenderType
> = {
    id: CellRenderType.TEXT,
    creationDate: CellRenderType.DATETIME,
    firstName: CellRenderType.TEXT,
    lastName: CellRenderType.TEXT,
    position: CellRenderType.TEXT,
    nameOfReport: CellRenderType.MARKUP,
    twitterNickName: CellRenderType.TEXT,
    visible: CellRenderType.BOOLEAN,
    conferences: CellRenderType.CONFERENCES,
};
