import React, { FC, PropsWithChildren, useState } from 'react';
import { Button, Calendar, Select, Space } from '@kl/components-v6';
import { Download } from '@kl/icons/16';
import { DATE_FORMAT } from 'consts/date';
import { FilterType, PageBuilderAdditionalFilters } from 'enums';
import { PageBuilderFilterType } from 'types';
import { useTranslation } from 'react-i18next';
import { filterToDropdown } from './mappers';
import { DebounceTextbox, SelectByArray, SelectByEnum } from 'components';

interface FiltersProps {
    setFilter: (newFilter: Record<string, string | number | undefined | null>) => void;
    disableExcelDownload: boolean;
    downloadExcel?: <TFilterParams>(params: Omit<TFilterParams, 'page' | 'size'>) => Promise<void>;
    additionalFilters?: {
        type: PageBuilderAdditionalFilters;
        key: FilterType;
        withEmpty?: boolean;
        defaultValue?: string;
        items?: {
            value: string | null;
            label: string;
        }[];
    }[];
    filters: PageBuilderFilterType;
    hideBaseFilters?: boolean;
}

export const Filters: FC<PropsWithChildren<FiltersProps>> = ({
    setFilter,
    disableExcelDownload,
    downloadExcel,
    additionalFilters,
    filters,
    hideBaseFilters,
}) => {
    const [excelLoading, setExcelLoading] = useState<boolean>(false);
    const { t } = useTranslation('common/shared');

    const generateTimezoneDate = (value: Date, hour: number, min: number, sec: number, ms: number) => {
        const currentTimezoneOffset = new Date().getTimezoneOffset();
        return new Date(
            new Date(value.setHours(hour, min, sec, ms)).getTime() - currentTimezoneOffset * 60000
        ).toISOString();
    };

    // only for calendars validation
    const [dates, setDates] = useState<{ startDate: Date | null; finishDate: Date | null }>({
        startDate: null,
        finishDate: null,
    });

    return (
        <Space direction="horizontal" size={15} style={{ marginBottom: 30, marginTop: 30, display: 'flex' }}>
            {!hideBaseFilters && (
                <>
                    <Calendar
                        style={{ width: 150 }}
                        placeholder={t('startDate')}
                        format={DATE_FORMAT}
                        allowClear
                        disabledDate={(current: Date) => {
                            if (dates?.finishDate) {
                                return current && current.valueOf() > dates?.finishDate.valueOf();
                            }
                            return false;
                        }}
                        onChange={(value: Date | null) => {
                            setDates((prevState) => ({ ...prevState, startDate: value }));
                            if (!value) {
                                setFilter({ startDate: undefined });
                            } else {
                                setFilter({ startDate: generateTimezoneDate(value, 0, 0, 0, 0) });
                            }
                        }}
                    />
                    <Calendar
                        style={{ width: 150 }}
                        placeholder={t('endDate')}
                        format={DATE_FORMAT}
                        allowClear
                        disabledDate={(current: Date) => {
                            if (dates?.startDate) {
                                return current && current.valueOf() < dates?.startDate.valueOf();
                            }
                            return false;
                        }}
                        onChange={(value: Date | null) => {
                            setDates((prevState) => ({ ...prevState, finishDate: value }));
                            if (!value) {
                                setFilter({ finishDate: undefined });
                            } else {
                                setFilter({ finishDate: generateTimezoneDate(value, 23, 59, 59, 0) });
                            }
                        }}
                    />
                </>
            )}

            {additionalFilters?.length &&
                additionalFilters.map(
                    (filter: {
                        type: PageBuilderAdditionalFilters;
                        key: FilterType;
                        defaultValue?: string;
                        items?: {
                            value: string | null;
                            label: string;
                        }[];
                    }) => {
                        const { type, key } = filter;

                        switch (type) {
                            case PageBuilderAdditionalFilters.Dropdown:
                                const { options, defaultValue } = filterToDropdown(key, filters);

                                return (
                                    <SelectByEnum
                                        key={key}
                                        filterType={key}
                                        defaultValue={defaultValue}
                                        select={(value: string) => setFilter({ [key]: value as string, page: 0 })}
                                        options={options}
                                    />
                                );
                            case PageBuilderAdditionalFilters.DropdownWithSearch:
                                return (
                                    <SelectByArray
                                        key={key}
                                        filterType={key}
                                        defaultValue={filter.defaultValue}
                                        withEmpty
                                        select={(val: string | null) => setFilter({ [key]: val as string })}
                                        options={filter.items as []}
                                    />
                                );
                            case PageBuilderAdditionalFilters.DebounceTextBox:
                                return (
                                    <DebounceTextbox
                                        key={key}
                                        filterType={key}
                                        change={(value) => setFilter({ [key]: value, page: 0 })}
                                    />
                                );
                        }
                    }
                )}

            {downloadExcel && (
                <Button
                    loading={excelLoading}
                    disabled={disableExcelDownload}
                    iconAfter={<Download key={'downloadIcon'} color={'#fff'} />}
                    mode="primaryBlack"
                    onClick={async () => {
                        setExcelLoading(true);
                        await downloadExcel(filters);
                        setExcelLoading(false);
                    }}
                >
                    {t('xlsDownload')}
                </Button>
            )}
        </Space>
    );
};
