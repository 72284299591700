import React, { FC } from 'react';
import { useMarketingRequests } from 'contexts/index';
import { cellRenderMapper, initialColumns } from './mappers';
import { MarketingRequest, GetMarketingRequests } from 'types/index';
import { renderCell, getTableColumns } from 'utils/index';
import { PageBuilder } from 'containers/index';
import { FilterType, PageBuilderAdditionalFilters, PageBuilderKey } from 'enums/index';
import { useTranslation } from 'react-i18next';

const MarketingRequests: FC = () => {
    const { marketingRequests, getMarketingRequests, getExcel, deleteMarketingRequests } = useMarketingRequests();
    const { i18n } = useTranslation('pages/marketing-requests');

    return (
        <PageBuilder<MarketingRequest>
            pageKey={PageBuilderKey.MarketingRequests}
            data={marketingRequests}
            getItems={(params) => getMarketingRequests(params as GetMarketingRequests)}
            getExcel={(params) => getExcel(params as Omit<GetMarketingRequests, 'page' | 'size'>)}
            deleteItems={deleteMarketingRequests}
            columns={getTableColumns<Omit<MarketingRequest, 'id'>>(
                initialColumns,
                cellRenderMapper,
                renderCell,
                i18n.getResourceBundle(i18n.language, 'pages/marketing-requests')
            )}
            additionalFilters={[{ type: PageBuilderAdditionalFilters.DebounceTextBox, key: FilterType.Email }]}
        />
    );
};

export default MarketingRequests;
