import React, { createContext, FC, Key, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { getApiService, getProgramEventsApiService, getStorageService } from 'services';
import { GetProgramEvents, HttpResponse, ProgramEvent } from 'types';
import { PaginationOutput, downloadFileFromBase64 } from 'kl-b2c-ui-kit';
import { dropdownToBoolean } from './mappers/dropdown-to-boolean';

interface ProgramEventsContext {
    programEvent: Omit<ProgramEvent, 'id' | 'creationDate'> | null;
    programEvents: PaginationOutput<ProgramEvent>;
    getProgramEvents: (params: GetProgramEvents) => Promise<void>;
    getProgramEvent: (id: string) => void;
    getExcel: (params: Omit<GetProgramEvents, 'page' | 'size'>) => Promise<void>;
    deleteProgramEvents: (ids: Key[]) => Promise<HttpResponse<string>>;
    addProgramEvent: (data: Omit<ProgramEvent, 'id' | 'creationDate'>) => void;
    updateProgramEvent: (data: Omit<ProgramEvent, 'creationDate'>) => void;
}

const ProgramEventsContext = createContext<ProgramEventsContext>({} as ProgramEventsContext);

const ProgramEventsProvider: FC<PropsWithChildren> = ({ children }) => {
    const storageService = getStorageService();
    const [programEvent, setProgramEvent] = useState<Omit<ProgramEvent, 'id' | 'creationDate'> | null>(null);
    const [programEvents, setProgramEvents] = useState<PaginationOutput<ProgramEvent>>({
        count: 0,
        items: null,
    });
    const apiService = getApiService(storageService);
    const ProgramEventsApiService = getProgramEventsApiService(apiService);

    const getProgramEvents = async (params: GetProgramEvents) => {
        const ProgramEvents = await ProgramEventsApiService.getItems({
            ...params,
            //@ts-ignore
            isVisible: dropdownToBoolean(params.isVisible),
        });
        setProgramEvents(ProgramEvents.data);
    };

    const getProgramEvent = async (id: string) => {
        const ProgramEvent = await ProgramEventsApiService.getItem(id);
        return setProgramEvent(ProgramEvent.data);
    };

    const addProgramEvent = async (data: Omit<ProgramEvent, 'id' | 'creationDate'>) => {
        await ProgramEventsApiService.add(data);
    };

    const updateProgramEvent = async (data: Omit<ProgramEvent, 'creationDate'>) => {
        await ProgramEventsApiService.update(data);
    };

    const deleteProgramEvents = async (ids: Key[]) => {
        return await ProgramEventsApiService.deleteMany(ids);
    };

    const getExcel = async (params: Omit<GetProgramEvents, 'page' | 'size'>) => {
        const base64 = await ProgramEventsApiService.getExcel(params);
        downloadFileFromBase64(base64.data, 'xlsx', 'programEvents');
    };

    const memoValue = useMemo(
        () => ({
            getProgramEvents,
            programEvents,
            getExcel,
            deleteProgramEvents,
            getProgramEvent,
            programEvent,
            addProgramEvent,
            updateProgramEvent,
        }),
        [getProgramEvents, programEvents, programEvent, addProgramEvent, updateProgramEvent]
    );

    return <ProgramEventsContext.Provider value={memoValue}>{children}</ProgramEventsContext.Provider>;
};

export const useProgramEvents = () => useContext(ProgramEventsContext);

export default ProgramEventsProvider;
