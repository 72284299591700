export enum PageBuilderKey {
    Agreements = 'Agreements',
    Participants = 'Participants',
    Sponsors = 'Sponsors',
    Speakers = 'Speakers',
    MarketingRequests = 'MarketingRequests',
    Conferences = 'Conferences',
    Committees = 'Committees',
    SpeakersTags = 'SpeakersTags',
    SpeakersContents = 'SpeakersContents',
    SponsorsContents = 'SponsorsContents',
    ProgramTags = 'ProgramTags',
    ProgramModerators = 'ProgramModerators',
    ProgramEvents = 'ProgramEvents',
    ProgramSessions = 'ProgramSessions',
}
