import { AgreementsChapter, PageBuilderKey } from 'enums';
import { PageBuilderFilterType } from 'types';

const baseFilters = {
    page: 0,
    size: 100,
};

export const getFilterMapper = (pageKey: PageBuilderKey): PageBuilderFilterType => {
    if (!filterMapper[pageKey]) {
        throw new Error(`There is no filter mapper for ${pageKey}, consider to add one in to filterMapper object`);
    }

    return filterMapper[pageKey];
};

export const filterMapper: Record<string, PageBuilderFilterType> = {
    Agreements: {
        ...baseFilters,
        chapter: AgreementsChapter.All,
    },
    Participants: {
        ...baseFilters,
    },
    Speakers: {
        ...baseFilters,
    },
    Sponsors: {
        ...baseFilters,
    },
    MarketingRequests: {
        ...baseFilters,
    },
    Conferences: {
        ...baseFilters,
    },
    Committees: {
        ...baseFilters,
        conferenceId: null,
    },
    SpeakersTags: {
        ...baseFilters,
    },
    SpeakersContents: {
        ...baseFilters,
    },
    SponsorsContents: {
        ...baseFilters,
    },
    ProgramTags: {
        ...baseFilters,
    },
    ProgramModerators: {
        ...baseFilters,
    },
    ProgramEvents: {
        ...baseFilters,
    },
    ProgramSessions: {
        ...baseFilters,
    },
};
