import React, { FC, useEffect } from 'react';
import { useConferences, useParticipants } from 'contexts';
import { initialColumns, cellRenderMapper } from './mappers';
import { Conference, GetParticipants, Participant } from 'types';
import { renderCell, getTableColumns } from 'utils';
import { PageBuilder } from 'containers';
import { FilterType, PageBuilderAdditionalFilters, PageBuilderKey } from 'enums';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Participants: FC = () => {
    const { participants, getParticipants, getExcel, deleteParticipants } = useParticipants();
    const { i18n } = useTranslation('common/shared');
    const navigate = useNavigate();

    const { getConferences, conferences, getCurrentConference, currentConference } = useConferences();

    const mapConferences = (conferences: Conference[]) =>
        conferences.map((item) => ({
            value: item.id,
            label: item.name,
        }));

    useEffect(() => {
        getConferences({ page: 0, size: 300 });
    }, []);

    return (
        <PageBuilder<Participant>
            pageKey={PageBuilderKey.Participants}
            data={participants}
            getItems={(params) => getParticipants(params as GetParticipants)}
            getExcel={(params) => getExcel(params as Omit<GetParticipants, 'page' | 'size'>)}
            deleteItems={deleteParticipants}
            showItem={(participant: Participant) => navigate(`/participant/${participant.id}`)}
            additionalFilters={[
                { type: PageBuilderAdditionalFilters.DebounceTextBox, key: FilterType.Email },
                {
                    type: PageBuilderAdditionalFilters.DropdownWithSearch,
                    key: FilterType.Conference,
                    items: mapConferences(conferences.items ?? []),
                    withEmpty: true,
                    defaultValueFetchAsync: () => getCurrentConference().then((d) => d?.id),
                    defaultValue: currentConference?.id,
                },
            ]}
            columns={getTableColumns<Omit<Participant, 'id'>>(
                initialColumns,
                cellRenderMapper,
                renderCell,
                i18n.getResourceBundle(i18n.language, 'common/shared')
            )}
        />
    );
};

export default Participants;
