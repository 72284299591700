import React, { createContext, FC, Key, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { getApiService, getProgramModeratorsApiService, getStorageService } from 'services';
import { GetProgramModerators, HttpResponse, ProgramModerator } from 'types';
import { PaginationOutput, downloadFileFromBase64 } from 'kl-b2c-ui-kit';

interface ProgramModeratorsContext {
    programModerator: Omit<ProgramModerator, 'id' | 'creationDate'> | null;
    programModerators: PaginationOutput<ProgramModerator>;
    getProgramModerators: (params: GetProgramModerators) => Promise<void>;
    getProgramModerator: (id: string) => void;
    getExcel: (params: Omit<GetProgramModerators, 'page' | 'size'>) => Promise<void>;
    deleteProgramModerators: (ids: Key[]) => Promise<HttpResponse<string>>;
    addProgramModerator: (data: Omit<ProgramModerator, 'id' | 'creationDate'>) => void;
    updateProgramModerator: (data: Omit<ProgramModerator, 'creationDate'>) => void;
}

const ProgramModeratorsContext = createContext<ProgramModeratorsContext>({} as ProgramModeratorsContext);

const ProgramModeratorsProvider: FC<PropsWithChildren> = ({ children }) => {
    const storageService = getStorageService();
    const [programModerator, setProgramModerator] = useState<Omit<ProgramModerator, 'id' | 'creationDate'> | null>(
        null
    );
    const [programModerators, setProgramModerators] = useState<PaginationOutput<ProgramModerator>>({
        count: 0,
        items: null,
    });
    const apiService = getApiService(storageService);
    const ProgramModeratorsApiService = getProgramModeratorsApiService(apiService);

    const getProgramModerators = async (params: GetProgramModerators) => {
        const ProgramModerators = await ProgramModeratorsApiService.getItems(params);
        setProgramModerators(ProgramModerators.data);
    };

    const getProgramModerator = async (id: string) => {
        const ProgramModerator = await ProgramModeratorsApiService.getItem(id);
        return setProgramModerator(ProgramModerator.data);
    };

    const addProgramModerator = async (data: Omit<ProgramModerator, 'id' | 'creationDate'>) => {
        await ProgramModeratorsApiService.add(data);
    };

    const updateProgramModerator = async (data: Omit<ProgramModerator, 'creationDate'>) => {
        await ProgramModeratorsApiService.update(data);
    };

    const deleteProgramModerators = async (ids: Key[]) => {
        return await ProgramModeratorsApiService.deleteMany(ids);
    };

    const getExcel = async (params: Omit<GetProgramModerators, 'page' | 'size'>) => {
        const base64 = await ProgramModeratorsApiService.getExcel(params);
        downloadFileFromBase64(base64.data, 'xlsx', 'programModerators');
    };

    const memoValue = useMemo(
        () => ({
            getProgramModerators,
            programModerators,
            getExcel,
            deleteProgramModerators,
            getProgramModerator,
            programModerator,
            addProgramModerator,
            updateProgramModerator,
        }),
        [getProgramModerators, programModerators, programModerator, addProgramModerator, updateProgramModerator]
    );

    return <ProgramModeratorsContext.Provider value={memoValue}>{children}</ProgramModeratorsContext.Provider>;
};

export const useProgramModerators = () => useContext(ProgramModeratorsContext);

export default ProgramModeratorsProvider;
