import { useSpeakers } from 'contexts';
import { FormBuilderKeys } from 'enums/form-builder-keys';
import { t } from 'i18next';
import { omit } from 'kl-b2c-ui-kit';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Speaker as SpeakerModel } from 'types';
import { FormBuilder } from '..';
import { Loader } from '@kl/components-v6';

const Speaker: FC = () => {
    const { getSpeaker } = useSpeakers();
    const { id } = useParams();
    const [speaker, setSpeaker] = useState<SpeakerModel | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    const methods = useForm<SpeakerModel>();

    useEffect(() => {
        if (id) {
            setLoading(true);
            getSpeaker(id).then((speaker) => {
                setSpeaker(speaker);
                setLoading(false);
            });
        }
    }, []);

    return speaker ? (
        <FormProvider {...methods}>
            <FormBuilder<SpeakerModel>
                data={speaker}
                formKey={FormBuilderKeys.Speaker}
                loading={loading}
                cancel={() => navigate('/speakers')}
            />
        </FormProvider>
    ) : (
        <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />
    );
};

export default Speaker;
