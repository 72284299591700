import React, { createContext, FC, Key, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { getApiService, getCommitteesApiService, getStorageService } from 'services';
import { GetCommittees, HttpResponse, Committee } from 'types';
import { PaginationOutput, downloadFileFromBase64, generateFormData } from 'kl-b2c-ui-kit';

interface CommitteesContext {
    committee: Omit<Committee, 'id' | 'creationDate'> | null;
    committees: PaginationOutput<Committee>;
    getCommittees: (params: GetCommittees) => Promise<void>;
    getCommittee: (id: string) => void;
    getExcel: (params: Omit<GetCommittees, 'page' | 'size'>) => Promise<void>;
    deleteCommittees: (ids: Key[]) => Promise<HttpResponse<string>>;
    addCommittee: (data: Omit<Committee, 'id' | 'creationDate'>) => void;
    updateCommittee: (data: Omit<Committee, 'creationDate'>) => void;
}

const CommitteesContext = createContext<CommitteesContext>({} as CommitteesContext);

const CommitteesProvider: FC<PropsWithChildren> = ({ children }) => {
    const storageService = getStorageService();
    const [committee, setCommittee] = useState<Omit<Committee, 'id' | 'creationDate'> | null>(null);
    const [committees, setCommittees] = useState<PaginationOutput<Committee>>({
        count: 0,
        items: null,
    });
    const apiService = getApiService(storageService);
    const committeesApiService = getCommitteesApiService(apiService);

    const getCommittees = async (params: GetCommittees) => {
        const committees = await committeesApiService.getItems(params);
        setCommittees(committees.data);
    };

    const getCommittee = async (id: string) => {
        const committee = await committeesApiService.getItem(id);
        return setCommittee(committee.data);
    };

    const addCommittee = async (data: Omit<Committee, 'id' | 'creationDate'>) => {
        const committee = await committeesApiService.add(generateFormData(data));
        setCommittee(committee.data);
    };

    const updateCommittee = async (data: Omit<Committee, 'creationDate'>) => {
        const committee = await committeesApiService.update(generateFormData(data));
        setCommittee(committee.data);
    };

    const deleteCommittees = async (ids: Key[]) => {
        return await committeesApiService.deleteMany(ids);
    };

    const getExcel = async (params: Omit<GetCommittees, 'page' | 'size'>) => {
        const base64 = await committeesApiService.getExcel(params);
        downloadFileFromBase64(base64.data, 'xlsx', 'committees');
    };

    const memoValue = useMemo(
        () => ({
            getCommittees,
            committees,
            getExcel,
            deleteCommittees,
            getCommittee,
            committee,
            addCommittee,
            updateCommittee,
        }),
        [getCommittees, committees, committee, addCommittee, updateCommittee]
    );

    return <CommitteesContext.Provider value={memoValue}>{children}</CommitteesContext.Provider>;
};

export const useCommittees = () => useContext(CommitteesContext);

export default CommitteesProvider;
