import React, { createContext, FC, Key, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { getApiService, getSponsorsContentsApiService, getStorageService } from 'services';
import { GetSponsorsContents, HttpResponse, SponsorsContent } from 'types';
import { PaginationOutput, downloadFileFromBase64, generateFormData } from 'kl-b2c-ui-kit';
import { dropdownToBoolean } from './mappers/dropdown-to-boolean';

interface SponsorsContentsContext {
    sponsorsContent: Omit<SponsorsContent, 'id' | 'creationDate'> | null;
    sponsorsContents: PaginationOutput<SponsorsContent>;
    getSponsorsContents: (params: GetSponsorsContents) => Promise<void>;
    getSponsorsContent: (id: string) => void;
    getExcel: (params: Omit<GetSponsorsContents, 'page' | 'size'>) => Promise<void>;
    deleteSponsorsContents: (ids: Key[]) => Promise<HttpResponse<string>>;
    addSponsorsContent: (data: Omit<SponsorsContent, 'id' | 'creationDate'>) => void;
    updateSponsorsContent: (data: Omit<SponsorsContent, 'creationDate'>) => void;
}

const SponsorsContentsContext = createContext<SponsorsContentsContext>({} as SponsorsContentsContext);

const SponsorsContentsProvider: FC<PropsWithChildren> = ({ children }) => {
    const storageService = getStorageService();
    const [sponsorsContent, setSponsorsContent] = useState<Omit<SponsorsContent, 'id' | 'creationDate'> | null>(null);
    const [sponsorsContents, setSponsorsContents] = useState<PaginationOutput<SponsorsContent>>({
        count: 0,
        items: null,
    });
    const apiService = getApiService(storageService);
    const sponsorsContentsApiService = getSponsorsContentsApiService(apiService);

    const getSponsorsContents = async (params: GetSponsorsContents) => {
        const sponsorsContents = await sponsorsContentsApiService.getItems({
            ...params,
            //@ts-ignore
            isVisible: dropdownToBoolean(params.isVisible),
        });
        setSponsorsContents(sponsorsContents.data);
    };

    const getSponsorsContent = async (id: string) => {
        const sponsorsContent = await sponsorsContentsApiService.getItem(id);
        return setSponsorsContent(sponsorsContent.data);
    };

    const addSponsorsContent = async (data: Omit<SponsorsContent, 'id' | 'creationDate'>) => {
        const sponsorsContent = await sponsorsContentsApiService.add(generateFormData(data));
        setSponsorsContent(sponsorsContent.data);
    };

    const updateSponsorsContent = async (data: Omit<SponsorsContent, 'creationDate'>) => {
        const sponsorsContent = await sponsorsContentsApiService.update(generateFormData(data));
        setSponsorsContent(sponsorsContent.data);
    };

    const deleteSponsorsContents = async (ids: Key[]) => {
        return await sponsorsContentsApiService.deleteMany(ids);
    };

    const getExcel = async (params: Omit<GetSponsorsContents, 'page' | 'size'>) => {
        const base64 = await sponsorsContentsApiService.getExcel(params);
        downloadFileFromBase64(base64.data, 'xlsx', 'sponsorsContents');
    };

    const memoValue = useMemo(
        () => ({
            getSponsorsContents,
            sponsorsContents,
            getExcel,
            deleteSponsorsContents,
            getSponsorsContent,
            sponsorsContent,
            addSponsorsContent,
            updateSponsorsContent,
        }),
        [getSponsorsContents, sponsorsContents, sponsorsContent, addSponsorsContent, updateSponsorsContent]
    );

    return <SponsorsContentsContext.Provider value={memoValue}>{children}</SponsorsContentsContext.Provider>;
};

export const useSponsorsContents = () => useContext(SponsorsContentsContext);

export default SponsorsContentsProvider;
