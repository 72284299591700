import { CellRenderType } from 'enums';
import { SponsorsContent } from 'types';

export const initialColumns: SponsorsContent = {
    id: '',
    creationDate: new Date(),
    isVisible: false,
    image: null,
    number: 1,
};

export const cellRenderMapper: Record<keyof SponsorsContent, CellRenderType> = {
    id: CellRenderType.TEXT,
    creationDate: CellRenderType.DATETIME,
    isVisible: CellRenderType.BOOLEAN,
    image: CellRenderType.IMAGE_PREVIEW,
    number: CellRenderType.TEXT,
};
