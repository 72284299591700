import React, { createContext, FC, Key, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { getApiService, getSpeakersTagsApiService, getStorageService } from 'services';
import { GetSpeakersTags, HttpResponse, SpeakersTag } from 'types';
import { PaginationOutput, downloadFileFromBase64 } from 'kl-b2c-ui-kit';

interface SpeakersTagsContext {
    speakersTag: Omit<SpeakersTag, 'id' | 'creationDate'> | null;
    speakersTags: PaginationOutput<SpeakersTag>;
    getSpeakersTags: (params: GetSpeakersTags) => Promise<void>;
    getSpeakersTag: (id: string) => void;
    getExcel: (params: Omit<GetSpeakersTags, 'page' | 'size'>) => Promise<void>;
    deleteSpeakersTags: (ids: Key[]) => Promise<HttpResponse<string>>;
    addSpeakersTag: (data: Omit<SpeakersTag, 'id' | 'creationDate'>) => void;
    updateSpeakersTag: (data: Omit<SpeakersTag, 'creationDate'>) => void;
}

const SpeakersTagsContext = createContext<SpeakersTagsContext>({} as SpeakersTagsContext);

const SpeakersTagsProvider: FC<PropsWithChildren> = ({ children }) => {
    const storageService = getStorageService();
    const [speakersTag, setSpeakersTag] = useState<Omit<SpeakersTag, 'id' | 'creationDate'> | null>(null);
    const [speakersTags, setSpeakersTags] = useState<PaginationOutput<SpeakersTag>>({
        count: 0,
        items: null,
    });
    const apiService = getApiService(storageService);
    const speakersTagsApiService = getSpeakersTagsApiService(apiService);

    const getSpeakersTags = async (params: GetSpeakersTags) => {
        const speakersTags = await speakersTagsApiService.getItems(params);
        setSpeakersTags(speakersTags.data);
    };

    const getSpeakersTag = async (id: string) => {
        const speakersTag = await speakersTagsApiService.getItem(id);
        return setSpeakersTag(speakersTag.data);
    };

    const addSpeakersTag = async (data: Omit<SpeakersTag, 'id' | 'creationDate'>) => {
        const speakersTag = await speakersTagsApiService.add(data);
        setSpeakersTag(speakersTag.data);
    };

    const updateSpeakersTag = async (data: Omit<SpeakersTag, 'creationDate'>) => {
        const speakersTag = await speakersTagsApiService.update(data);
        setSpeakersTag(speakersTag.data);
    };

    const deleteSpeakersTags = async (ids: Key[]) => {
        return await speakersTagsApiService.deleteMany(ids);
    };

    const getExcel = async (params: Omit<GetSpeakersTags, 'page' | 'size'>) => {
        const base64 = await speakersTagsApiService.getExcel(params);
        downloadFileFromBase64(base64.data, 'xlsx', 'speakersTags');
    };

    const memoValue = useMemo(
        () => ({
            getSpeakersTags,
            speakersTags,
            getExcel,
            deleteSpeakersTags,
            getSpeakersTag,
            speakersTag,
            addSpeakersTag,
            updateSpeakersTag,
        }),
        [getSpeakersTags, speakersTags, speakersTag, addSpeakersTag, updateSpeakersTag]
    );

    return <SpeakersTagsContext.Provider value={memoValue}>{children}</SpeakersTagsContext.Provider>;
};

export const useSpeakersTags = () => useContext(SpeakersTagsContext);

export default SpeakersTagsProvider;
