import { useSponsorsContents, useToaster } from 'contexts';
import { FormBuilderKeys } from 'enums/form-builder-keys';
import React, { FC, useEffect, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { SponsorsContent as SponsorsContentModel } from 'types';
import { Heading, Loader, Select } from '@kl/components-v6';
import { FormBuilder } from 'containers';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';

const INITIAL_DATA: SponsorsContentModel = {
    id: '',
    creationDate: new Date(),
    isVisible: false,
    number: 1,
    image: null,
};

const SponsorsContent: FC = () => {
    const { getSponsorsContent, sponsorsContent, updateSponsorsContent, addSponsorsContent } = useSponsorsContents();
    const { id } = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const { t } = useTranslation(['pages/sponsors-contents', 'common/shared']);
    const { setToaster } = useToaster();

    const methods = useForm<SponsorsContentModel>();

    const {
        control,
        formState: { errors },
    } = methods;

    const onSubmit = async (data: FieldValues) => {
        setLoading(true);
        try {
            if (id) {
                await updateSponsorsContent({ ...data, id } as SponsorsContentModel);
            } else {
                await addSponsorsContent(data as SponsorsContentModel);
            }
            navigate('/content/sponsors');
        } catch (e: unknown) {
            setToaster({
                message: (e as AxiosError).message ?? t('somethingWrong', { ns: 'common/shared' }),
                type: 'error',
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (id) {
            getSponsorsContent(id);
        }
    }, []);

    if (id && !sponsorsContent) {
        return <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />;
    }

    return (
        <>
            <Heading type={'H2'}>{id ? t('update-sponsorsContent') : t('add-sponsorsContent')}</Heading>
            <FormProvider {...methods}>
                <FormBuilder<Omit<SponsorsContentModel, 'id' | 'creationDate'>>
                    data={sponsorsContent || INITIAL_DATA}
                    formKey={FormBuilderKeys.SponsorsContent}
                    loading={loading}
                    isFormEmpty={!id}
                    submit={onSubmit}
                    cancel={() => navigate('/content/sponsors')}
                />
            </FormProvider>
        </>
    );
};

export default SponsorsContent;
