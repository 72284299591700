import { ProgramSession } from 'types';
import { ControlRendererType } from 'enums';
import { ControlRendererRepresentation } from 'types/form-builder/control-renderer-config';

type EditType = Omit<ProgramSession, 'id' | 'creationDate'>;

export const programSessionRendererTypes = (
    t: (key: string, options?: Record<string, number | string>) => string
): Record<keyof EditType, ControlRendererRepresentation> => ({
    startTime: {
        type: ControlRendererType.Time,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 5,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 5,
                }),
            },
        },
    },
    endTime: {
        type: ControlRendererType.Time,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 5,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 5,
                }),
            },
        },
    },
    date: {
        type: ControlRendererType.TimePicker,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 255,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 255,
                }),
            },
        },
    },
    location: {
        type: ControlRendererType.TextBox,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 100,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 100,
                }),
            },
        },
    },
    name: {
        type: ControlRendererType.TextBox,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 200,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 200,
                }),
            },
        },
    },
    description: {
        type: ControlRendererType.Wysiwyg,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 500,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 500,
                }),
            },
        },
    },
    visible: {
        type: ControlRendererType.CheckBox,
    },
    programEventIds: {
        type: ControlRendererType.Hidden,
    },
    programEvents: {
        type: ControlRendererType.Hidden,
    },
    moderatorId: {
        type: ControlRendererType.Hidden,
    },
    moderator: {
        type: ControlRendererType.Hidden,
    },
    conferenceId: {
        type: ControlRendererType.Hidden,
    },
    conference: {
        type: ControlRendererType.Hidden,
    },
});
