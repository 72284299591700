import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon, Link } from '@kl/components-v6';
import { GetFile, toDateTime, toDate } from 'kl-b2c-ui-kit';
import { colors } from '@kl/components-v6/design-system/theme/themes/dark/colors';
import { ImagePreview, ShowMarkup } from 'components';
import { AgreementsChapter, CellRenderType } from 'enums';
import { Conference, SpeakersTag } from 'types';
import { Text } from './styled';

export const renderCell = (value: unknown, type: CellRenderType) => {
    switch (type) {
        case CellRenderType.BOOLEAN:
            const config = {
                name: value ? 'Check' : 'Minus',
                color: value ? colors['criticalitystatuses'].positive : colors['criticalitystatuses'].critical,
            };
            // @ts-ignore
            return <Icon key={window.crypto.randomUUID()} size={'small'} name={config.name} color={config.color} />;
        case CellRenderType.DATETIME:
            return <Text>{toDateTime(value as string)}</Text>;
        case CellRenderType.DATE_FIX_TIMEZONE:
            if (!value) return '';
            const currentTimezoneOffset = new Date().getTimezoneOffset();
            const dateStr = new Date(new Date(value as string).getTime() + currentTimezoneOffset * 60000).toISOString();
            return <Text>{toDateTime(dateStr)}</Text>;
        case CellRenderType.DATE:
            return <Text>{toDate(value as string)}</Text>;
        case CellRenderType.MARKUP:
            if (!value) return null;
            return <ShowMarkup markUp={value as string} />;
        case CellRenderType.FILE:
            if (!value) {
                return <Text>File has not been provided</Text>;
            }

            const { fileName, fileLink } = value as GetFile;
            return (
                <Link href={fileLink} target="__blank">
                    {fileName}
                </Link>
            );
        case CellRenderType.IMAGE_PREVIEW:
            const file = value as GetFile;

            if (!file) {
                return <Text>No image has been provided</Text>;
            }

            return <ImagePreview image={file} />;
        // TODO: This should accept dynamic enum...
        case CellRenderType.CHAPTER:
            return <Text>{AgreementsChapter[value as AgreementsChapter]}</Text>;
        case CellRenderType.AGREEMENT_ID:
            return <RouterLink to={`/agreements/${value as string}`}>{value as string}</RouterLink>;
        case CellRenderType.CONFERENCE:
            return <Text>{(value as Conference)?.name}</Text>;
        case CellRenderType.CONFERENCES:
            return <Text>{(value as Conference[]).map((conference) => conference.name).join(', ')}</Text>;
        case CellRenderType.SPEAKER_TAG:
            return <Text>{(value as SpeakersTag)?.name}</Text>;
        default:
            return <Text>{value as string}</Text>;
    }
};
