import React, { FC, useState } from 'react';
import { FormProvider, useForm, FieldValues } from 'react-hook-form';
import { useConfig } from 'contexts';
import { Heading, Loader } from '@kl/components-v6';
import { FormBuilder } from 'containers';
import { GlobalSiteConfigUpdate } from 'types';
import { FormBuilderKeys } from 'enums';
import { useTranslation } from 'react-i18next';

const Config: FC = () => {
    const { config, updateConfig, conference } = useConfig();
    const { t } = useTranslation(['pages/config', 'common/shared', 'pages/form-builder', 'pages/conferences']);
    const [loading, setLoading] = useState<boolean>(false);

    const methods = useForm<GlobalSiteConfigUpdate>();

    const onSubmit = async (data: FieldValues) => {
        setLoading(true);
        try {
            await updateConfig(data as GlobalSiteConfigUpdate);
            methods.reset({ ...data });
        } catch (e) {
            methods.reset({ ...data, isOpened: false });
            throw e;
        } finally {
            setLoading(false);
        }
    };

    return config ? (
        <>
            <Heading type={'H2'}>{t('pageTitle')}</Heading>
            {conference && (
                <Heading style={{ marginTop: 30 }} type={'H5'}>
                    {t('current-conference', {
                        ns: 'pages/conferences',
                        currentConference: conference.name,
                    })}
                </Heading>
            )}
            <FormProvider {...methods}>
                <FormBuilder<GlobalSiteConfigUpdate>
                    data={config}
                    submit={onSubmit}
                    formKey={FormBuilderKeys.Config}
                    loading={loading}
                />
            </FormProvider>
        </>
    ) : (
        <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />
    );
};

export default Config;
