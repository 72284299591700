import { SponsorsContent } from 'types';
import { ControlRendererType } from 'enums';
import { ControlRendererRepresentation } from 'types/form-builder/control-renderer-config';
import { fileSize } from 'containers/form-builder/rules';
import { GetFile } from 'kl-b2c-ui-kit';
import { ACCEPTED_IMAGE_FORMATS, TEN_MB_IN_BYTES } from 'consts';

type EditType = Omit<SponsorsContent, 'id' | 'creationDate'>;

export const sponsorsContentRendererTypes = (
    t: (key: string, options?: Record<string, number | string>) => string
): Record<keyof EditType, ControlRendererRepresentation> => ({
    isVisible: {
        type: ControlRendererType.CheckBox,
    },
    number: {
        type: ControlRendererType.Number,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            validate: (value: number) => {
                if (!Number.isInteger(Number(value)) || Number(value) < 1) {
                    return t('only-natural', { ns: 'common/errors' });
                }
                return true;
            },
        },
    },
    image: {
        type: ControlRendererType.UploadImage,
        rules: {
            required: {
                value: true,
                message: t('required-image', {
                    ns: 'common/errors',
                }),
            },
            validate: (file: File | GetFile) => {
                return fileSize(file, TEN_MB_IN_BYTES, ACCEPTED_IMAGE_FORMATS, t);
            },
        },
        image: {
            size: TEN_MB_IN_BYTES,
        },
    },
});
