import React, { ChangeEvent, FC, useState } from 'react';
import { Delete } from '@kl/icons/16';
import { ACCEPTED_IMAGE_FORMATS, IMAGE_MAX_SIZE_BYTES } from 'consts';
import { HiddenInput, Label, LabelContainer } from './styled';
import { useTranslation } from 'react-i18next';
import { formatBytes } from 'kl-b2c-ui-kit';

interface ImageUploaderProps {
    change: (file: File | null) => void;
    accept?: string;
    maxSize?: number;
}

const ImageUploader: FC<ImageUploaderProps> = (props) => {
    const { change, accept = ACCEPTED_IMAGE_FORMATS, maxSize = IMAGE_MAX_SIZE_BYTES } = props;
    const { t } = useTranslation('common/shared');

    const [file, setFile] = useState<File | null>(null);

    return file ? (
        <>
            <span>{file.name}</span>
            <Delete
                onClick={() => {
                    setFile(null);
                    change(null);
                }}
            />
        </>
    ) : (
        <>
            <HiddenInput
                id={'file-uploader'}
                type={'file'}
                accept={accept}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    const files = (event.target as HTMLInputElement).files;
                    if (files && files[0]) {
                        setFile(files[0]);
                        change(files[0]);
                    }
                }}
            />
            <LabelContainer>
                <Label htmlFor={'file-uploader'}>{t('upload-image')}</Label>
                <span>({t('image-help-message', { formats: accept, size: formatBytes(maxSize) })})</span>
            </LabelContainer>
        </>
    );
};

export default ImageUploader;
