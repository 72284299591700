import React from 'react';
import { CellRenderType } from 'enums';
import { Agreement } from 'types';

export const initialColumns: Omit<Agreement, 'privacyPolicyText' | 'touText' | 'touCheckBoxText' | 'chapter'> = {
    id: '',
    creationDate: new Date(),
    email: '',
    privacyPolicyIsSelected: false,
    marketingIsSelected: false,
    marketingCheckBoxText: '',
    privacyPolicyCheckBoxText: '',
};

export const cellRenderMapper: Record<
    keyof Omit<Agreement, 'privacyPolicyText' | 'touText' | 'touCheckBoxText' | 'chapter'>,
    CellRenderType
> = {
    id: CellRenderType.TEXT,
    creationDate: CellRenderType.DATETIME,
    email: CellRenderType.TEXT,
    marketingCheckBoxText: CellRenderType.MARKUP,
    privacyPolicyCheckBoxText: CellRenderType.MARKUP,
    privacyPolicyIsSelected: CellRenderType.BOOLEAN,
    marketingIsSelected: CellRenderType.BOOLEAN,
};
