import { useProgramModerators, useToaster } from 'contexts';
import { FormBuilderKeys } from 'enums/form-builder-keys';
import React, { FC, useEffect, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { ProgramModerator as ProgramModeratorModel } from 'types';
import { Heading, Loader } from '@kl/components-v6';
import { FormBuilder } from 'containers';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';

const INITIAL_DATA: Omit<ProgramModeratorModel, 'id' | 'creationDate'> = {
    firstName: '',
    lastName: '',
    position: '',
};

const ProgramModerator: FC = () => {
    const { getProgramModerator, programModerator, updateProgramModerator, addProgramModerator } =
        useProgramModerators();
    const { id } = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const { t } = useTranslation(['pages/program-moderators', 'common/shared']);
    const { setToaster } = useToaster();

    const methods = useForm<ProgramModeratorModel>();

    const onSubmit = async (data: FieldValues) => {
        setLoading(true);
        try {
            if (id) {
                await updateProgramModerator({ ...data, id } as ProgramModeratorModel);
            } else {
                await addProgramModerator(data as ProgramModeratorModel);
            }
            navigate('/program/moderators');
        } catch (e: unknown) {
            setToaster({
                message: (e as AxiosError).message ?? t('somethingWrong', { ns: 'common/shared' }),
                type: 'error',
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (id) {
            getProgramModerator(id);
        }
    }, []);

    if (id && !programModerator) {
        return <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />;
    }

    return (
        <>
            <Heading type={'H2'}>{id ? t('update-programModerator') : t('add-programModerator')}</Heading>
            <FormProvider {...methods}>
                <FormBuilder<Omit<ProgramModeratorModel, 'id' | 'creationDate'>>
                    data={programModerator || INITIAL_DATA}
                    formKey={FormBuilderKeys.ProgramModerator}
                    loading={loading}
                    isFormEmpty={!id}
                    submit={onSubmit}
                    cancel={() => navigate('/program/moderators')}
                />
            </FormProvider>
        </>
    );
};

export default ProgramModerator;
