export enum ControlRendererType {
    Toggle = 'Toggle',
    Text = 'Text',
    DateTime = 'DateTime',
    Time = 'Time',
    Calendar = 'Calendar',
    TextBox = 'TextBox',
    DomainType = 'DomainType',
    CheckBox = 'CheckBox',
    UploadImage = 'UploadImage',
    Link = 'Link',
    Number = 'Number',
    Wysiwyg = 'Wysiwyg',
    Hidden = 'Hidden',
    TimePicker = 'TimePicker',
}
